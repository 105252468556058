import './Banner.css'
import {Link} from 'react-router-dom'
import { Banner_svg } from '../../../assets/SVG'
import { useNavigate } from 'react-router-dom';

const onDiv =(e)=>{
  let x = e.clientX;
  let y = e.clientY;

  const infoElement = document.getElementById('cursor');
  infoElement.style.display = "block";
  infoElement.style.top = (y) + "px";
  infoElement.style.left = (x) + "px";
}
const NotonDiv =()=>{
  const infoElement = document.getElementById('cursor');
  infoElement.style.display = "none";
}



const Banner = () => {
  const navigate = useNavigate()
      
  return (
    <div>
          <div id='cursor'>
            view
          </div>

    <section className='Banner' data-aos="fade-up" data-aos-anchor=".other-element">
          <div className="blob1" data-aos="slide-right"></div>

        <div className="Banner_left" data-aos="fade-up">
            <div className="learn_more">
                {Banner_svg.svg1}
              <p>v1.0 released</p>
            </div>
            <h2>Your data with real-time analytics</h2>
            <p>Harness the potential of FORI Analytics & Cloud Services and become a data-driven organization</p>
            <p className='para_show'>have any questions?  <Link className='Banner_links' to="/contactUs">Contact Us</Link> </p>
            <div className='button_container'>
                <Link className='btn' to='/dashboards'>My Dashboard</Link>
                <Link className='secondary_btn' to='/connect'>Setup</Link>
                <Link className='secondary_btn' to='/viewDemo/example'>View Demo</Link>
            </div>
        </div>

        <div data-aos="fade-up" data-aos-offset="100" onMouseEnter={(e)=>onDiv(e)}  onMouseMove={(e)=>onDiv(e)} onMouseLeave={(e)=>NotonDiv(e)} onClick={()=>navigate('/viewDemo/example')} className="Banner_right">
            <img src={require('../../../assets/Banner2.png')} alt="" />
        </div>
        
    </section>
    </div>

    
  )
}

export default Banner