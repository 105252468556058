import {Link} from 'react-router-dom'
import {BiLogOut} from 'react-icons/bi'
import {RiApps2Line} from 'react-icons/ri'
import {CgProfile} from 'react-icons/cg'
import {AiOutlineSetting} from 'react-icons/ai'
import {TbDatabaseExport} from 'react-icons/tb'
import {SiGoogleanalytics} from 'react-icons/si'
import { useState } from 'react'
import {logo_complete} from '../../assets/SVG'
const DemoSideBar = () => {
    const [activeNav, setActiveNav] = useState('dashboard')
  return (

    <section className="sidebar">
        <Link to='/' className='logo_complete'>{logo_complete}</Link>
        <div className="sidebar_button_container">
            <Link onClick={()=>setActiveNav('dashboard')} className={activeNav==='dashboard' ? 'sidebar_btn_active':'sidebar_btn_deactive'} to='/viewDemo/example'><RiApps2Line/>Dashboard</Link>
            <Link onClick={()=>setActiveNav('profile')} className={activeNav==='profile' ? 'sidebar_btn_active':'sidebar_btn_deactive'} to='/viewDemo/profile'><CgProfile/>My Profile</Link>
            <Link onClick={()=>setActiveNav('impact')} className={activeNav==='impact' ? 'sidebar_btn_active':'sidebar_btn_deactive'} to='/viewDemo/imapctAnalysis'><SiGoogleanalytics/>Imapct Analysis</Link>
            <Link onClick={()=>setActiveNav('previous')} className={activeNav==='previous' ? 'sidebar_btn_active':'sidebar_btn_deactive'} to='/viewDemo/pastDetails'><TbDatabaseExport />Previous Data</Link>
            <Link onClick={()=>setActiveNav('customise')} className={activeNav==='customise' ? 'sidebar_btn_active':'sidebar_btn_deactive'} to='/viewDemo/customiseDashboard'><AiOutlineSetting />Settings</Link>

        </div>
        <div><BiLogOut/> Log out</div>
    </section>
  )
}

export default DemoSideBar