let borderColor =  "rgba(114,46,209,1)"
let backgroundColor=  "rgba(114,46,209,0.71)"

export const DummyData = {
    year: {
      TotalRevenueGraph: {
        labels: [
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023"
        ],
        datasets: [
          {
            label: "Total revenue",
            data: [
              522225.5000000034,
              432287.74999999977,
              442338.0999999982,
              444474.7499999987,
              462615.79999999783,
              49489.95
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },

      AverageRevenueGraph: {
        labels: [
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023"
        ],
        datasets: [
          {
            label: "Average revenue",
            data: [
              24.746505236222497,
              24.996400485717576,
              24.982384502428452,
              24.851817165222183,
              25.097151847230393,
              25.045521255060727
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      NoOfRidesGraph: {
        labels: [
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023"
        ],
        datasets: [
          {
            label: "No fo Orders",
            data: [
              21103,
              17294,
              17706,
              17885,
              18433,
              1976
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },

      Co2Saved: {
        labels: [
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023"
        ],
        datasets: [
          {
            label: "Co2 Saved",
            data: [
              1357.786299999992,
              1123.948149999992,
              1150.079059999999,
              1155.634349999999,
              1202.8010799999975,
              128.67386999999997
            ],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)"
          }
        ]
      },
      FuelAndTreesGraph: {
        labels: [
          "2018",
          "2019",
          "2020",
          "2021",
          "2022",
          "2023"
        ],
        datasets: [
          {
            label: "Fuel Saved",
            data: [
              4177.804000000013,
              3458.3020000000015,
              3538.7048000000077,
              3555.7980000000252,
              3700.9263999999844,
              395.91960000000034
            ],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)"
          },
          {
            label: "Trees Relaxed",
            data: [
              12533.412000000077,
              10374.905999999999,
              10616.114400000048,
              10667.39400000003,
              11102.779200000074,
              1187.7587999999976
            ],
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)"
          }
        ]
      },
      PeakDays: {
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        datasets: [
          {
            label: "No of Orders",
            data: [
              13330,
              13757,
              13410,
              12851,
              13429,
              14028,
              13592
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      cardData: {
        totalCost: 2352059.3999999827,
        totalRides: 94341,
        totalDistance: 470411.8799999991,
        Num_of_users: 7638,
        totaCo2Saved: 6115.354439999942,
        totalFuelSaved: 18816.475200000332,
        totalTreesRelaxed: 5644.942560001018
      },
      LocationAndRevenue: {
        labels: [
          "Tromso",
          "Haugesund",
          "Bergen",
          "Svolvær",
          "Narvik",
          "Steinkjer",
          "Kabelvåg",
          "Porsgrunn",
          "Arendal",
          "Lillehammer",
          "Trondheim",
          "Moss",
          "Hammerfest",
          "Molde",
          "Drammen",
          "Sandnes",
          "Halden",
          "Oslo",
          "Skien",
          "Ringsaker"
        ],
        datasets: [
          {
            label: "Total Revenue",
            data: [
              80351.69999999991,
              78854.55000000026,
              78047.14999999994,
              77896.40000000004,
              77846.74999999999,
              77670.14999999989,
              77446.0500000002,
              77232.85000000005,
              77146.7,
              76923.54999999992,
              76615.70000000013,
              76523.79999999994,
              76489.65000000008,
              76371.55000000012,
              76305.34999999979,
              76223.20000000006,
              76107.7500000001,
              75803.70000000019,
              75242.7499999999,
              75156.25000000003
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      LocationAndRides: {
        datasets: [
          {
            label: "Tromso",
            data: [
              {
                x: 3159,
                y: 16070.340000000011,
                r: 25
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Haugesund",
            data: [
              {
                x: 3114,
                y: 15770.909999999983,
                r: 13.046467993375595
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Bergen",
            data: [
              {
                x: 3089,
                y: 15609.43,
                r: 6.600031936764858
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Svolvær",
            data: [
              {
                x: 3092,
                y: 15579.27999999999,
                r: 5.396415098106181
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Narvik",
            data: [
              {
                x: 3086,
                y: 15569.349999999995,
                r: 5
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      LocationAndUsers: {
        labels: [
          "Tromso",
          "Haugesund",
          "Bergen",
          "Svolvær",
          "Narvik",
          "Steinkjer",
          "Kabelvåg",
          "Porsgrunn",
          "Arendal",
          "Lillehammer",
          "Trondheim",
          "Moss",
          "Hammerfest",
          "Molde",
          "Drammen",
          "Sandnes",
          "Halden",
          "Oslo",
          "Skien",
          "Ringsaker"
        ],
        datasets: [
          {
            label: "Total Users",
            data: [
              2413,
              2399,
              2355,
              2385,
              2389,
              2366,
              2354,
              2359,
              2352,
              2375,
              2354,
              2357,
              2333,
              2396,
              2369,
              2336,
              2346,
              2344,
              2337,
              2316
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      }
    },
    month12: {
      TotalRevenueGraph: {
        labels: [
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12",
          "2023-01",
          "2023-02"
        ],
        datasets: [
          {
            label: "Total revenue",
            data: [
              23979.80000000002,
              40014.50000000002,
              43595.50000000006,
              41905.700000000004,
              39689.149999999994,
              40879.84999999996,
              34489.20000000001,
              34943.50000000001,
              38214.19999999994,
              39906.09999999995,
              37902.05,
              42447.45,
              7042.499999999996
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      AverageRevenueGraph: {
        labels: [
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12",
          "2023-01",
          "2023-02"
        ],
        datasets: [
          {
            label: "Average revenue",
            data: [
              24.46918367346941,
              24.869173399627112,
              25.52429742388762,
              24.8109532267614,
              25.247550890585238,
              24.760660205935775,
              25.397054491899862,
              25.71265636497425,
              24.543481053307605,
              25.209159823120626,
              25.100695364238412,
              24.76514002333722,
              26.8797709923664
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      NoOfRidesGraph: {
        labels: [
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12",
          "2023-01",
          "2023-02"
        ],
        datasets: [
          {
            label: "No fo Orders",
            data: [
              980,
              1609,
              1708,
              1689,
              1572,
              1651,
              1358,
              1359,
              1557,
              1583,
              1510,
              1714,
              262
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      Co2Saved: {
        labels: [
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12",
          "2023-01",
          "2023-02"
        ],
        datasets: [
          {
            label: "Co2 Saved",
            data: [
              62.347479999999976,
              104.03769999999989,
              113.34829999999998,
              108.95482000000015,
              103.1917899999998,
              106.28760999999993,
              89.67192000000009,
              90.85309999999996,
              99.35691999999985,
              103.75585999999991,
              98.54533,
              110.36336999999996,
              18.310499999999994
            ],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)"
          }
        ]
      },
      FuelAndTreesGraph: {
        labels: [
          "2022-02",
          "2022-03",
          "2022-04",
          "2022-05",
          "2022-06",
          "2022-07",
          "2022-08",
          "2022-09",
          "2022-10",
          "2022-11",
          "2022-12",
          "2023-01",
          "2023-02"
        ],
        datasets: [
          {
            label: "Fuel Saved",
            data: [
              191.8384000000002,
              320.1160000000006,
              348.7640000000003,
              335.2456000000004,
              317.5132000000004,
              327.0388000000004,
              275.91360000000054,
              279.5480000000006,
              305.7136000000006,
              319.24880000000053,
              303.2164000000003,
              339.5796000000002,
              56.34000000000002
            ],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)"
          },
          {
            label: "Trees Relaxed",
            data: [
              575.5151999999989,
              960.3479999999993,
              1046.2919999999997,
              1005.7368000000002,
              952.5395999999997,
              981.1164000000001,
              827.7407999999999,
              838.6439999999993,
              917.1408,
              957.7464000000006,
              909.6492000000005,
              1018.7387999999975,
              169.01999999999984
            ],
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)"
          }
        ]
      },
      PeakDays: {
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        datasets: [
          {
            label: "No of Orders",
            data: [
              2707,
              2713,
              2528,
              2698,
              2743,
              2613,
              2550
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      cardData: {
        totalCost: 465009.49999999884,
        totalRides: 18552,
        totalDistance: 93001.90000000002,
        Num_of_users: 4879,
        totaCo2Saved: 1209.0247000000004,
        totalFuelSaved: 3720.075999999975,
        totalTreesRelaxed: 1116.0227999999977
      },
      LocationAndRevenue: {
        labels: [
          "Tromso",
          "Haugesund",
          "Steinkjer",
          "Hammerfest",
          "Narvik",
          "Drammen",
          "Arendal",
          "Halden",
          "Porsgrunn",
          "Svolvær",
          "Trondheim",
          "Moss",
          "Vadso",
          "Kristiansund",
          "Molde",
          "Sandnes",
          "Skien",
          "Kristiansand",
          "Sandefjord",
          "Bergen"
        ],
        datasets: [
          {
            label: "Total Revenue",
            data: [
              17223.40000000001,
              16181.600000000008,
              15785.650000000001,
              15579.799999999992,
              15562.999999999996,
              15509.449999999986,
              15486.499999999989,
              15441.850000000002,
              15392.90000000002,
              15390.050000000005,
              15220.249999999998,
              15073.149999999994,
              15052.500000000002,
              14901.500000000007,
              14888.750000000013,
              14865.600000000002,
              14805.150000000012,
              14783.949999999993,
              14727.25,
              14709.449999999999
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      LocationAndRides: {
        datasets: [
          {
            label: "Tromso",
            data: [
              {
                x: 677,
                y: 3444.6800000000003,
                r: 25
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Haugesund",
            data: [
              {
                x: 648,
                y: 3236.3199999999993,
                r: 12.45121657431952
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Steinkjer",
            data: [
              {
                x: 625,
                y: 3157.130000000001,
                r: 7.681883883401631
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Hammerfest",
            data: [
              {
                x: 612,
                y: 3115.96,
                r: 5.202360876897079
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Narvik",
            data: [
              {
                x: 619,
                y: 3112.5999999999985,
                r: 5
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      LocationAndUsers: {
        labels: [
          "Tromso",
          "Haugesund",
          "Steinkjer",
          "Hammerfest",
          "Narvik",
          "Drammen",
          "Arendal",
          "Halden",
          "Porsgrunn",
          "Svolvær",
          "Trondheim",
          "Moss",
          "Vadso",
          "Kristiansund",
          "Molde",
          "Sandnes",
          "Skien",
          "Kristiansand",
          "Sandefjord",
          "Bergen"
        ],
        datasets: [
          {
            label: "Total Users",
            data: [
              643,
              613,
              590,
              573,
              588,
              579,
              574,
              581,
              571,
              571,
              577,
              559,
              559,
              569,
              577,
              557,
              546,
              558,
              565,
              564
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      }
    },
    days30: {
      TotalRevenueGraph: {
        labels: [
          "2023-01-07",
          "2023-01-08",
          "2023-01-09",
          "2023-01-10",
          "2023-01-11",
          "2023-01-12",
          "2023-01-13",
          "2023-01-14",
          "2023-01-15",
          "2023-01-16",
          "2023-01-17",
          "2023-01-18",
          "2023-01-19",
          "2023-01-20",
          "2023-01-21",
          "2023-01-22",
          "2023-01-23",
          "2023-01-24",
          "2023-01-25",
          "2023-01-26",
          "2023-01-27",
          "2023-01-28",
          "2023-01-29",
          "2023-01-30",
          "2023-01-31",
          "2023-02-01",
          "2023-02-02",
          "2023-02-03",
          "2023-02-04",
          "2023-02-05",
          "2023-02-06"
        ],
        datasets: [
          {
            label: "Total revenue",
            data: [
              7.2,
              1003.55,
              2529.6499999999987,
              641.6000000000001,
              1913.4,
              774.9000000000003,
              1868.0499999999997,
              2411.4000000000005,
              2559.3999999999996,
              1337.5500000000004,
              324.2,
              561.4499999999999,
              668.9499999999999,
              2248.25,
              2240.8500000000004,
              712.9,
              2256.7,
              2232.45,
              143.6,
              2073.2499999999995,
              1394.4000000000003,
              1223.35,
              709.5500000000001,
              1071.0500000000002,
              1086.2,
              1575.6499999999996,
              613.15,
              1578.1000000000006,
              396.04999999999995,
              1657.8500000000001,
              1221.7000000000003
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      AverageRevenueGraph: {
        labels: [
          "2023-01-07",
          "2023-01-08",
          "2023-01-09",
          "2023-01-10",
          "2023-01-11",
          "2023-01-12",
          "2023-01-13",
          "2023-01-14",
          "2023-01-15",
          "2023-01-16",
          "2023-01-17",
          "2023-01-18",
          "2023-01-19",
          "2023-01-20",
          "2023-01-21",
          "2023-01-22",
          "2023-01-23",
          "2023-01-24",
          "2023-01-25",
          "2023-01-26",
          "2023-01-27",
          "2023-01-28",
          "2023-01-29",
          "2023-01-30",
          "2023-01-31",
          "2023-02-01",
          "2023-02-02",
          "2023-02-03",
          "2023-02-04",
          "2023-02-05",
          "2023-02-06"
        ],
        datasets: [
          {
            label: "Average revenue",
            data: [
              7.2,
              25.73205128205128,
              26.62789473684209,
              25.664000000000005,
              25.176315789473687,
              25.83000000000001,
              26.686428571428568,
              26.498901098901104,
              29.760465116279065,
              20.899218750000006,
              32.42,
              26.735714285714284,
              24.77592592592592,
              26.142441860465116,
              23.58789473684211,
              25.460714285714285,
              24.798901098901098,
              25.36875,
              17.95,
              23.036111111111104,
              21.787500000000005,
              28.45,
              20.272857142857145,
              21.421000000000003,
              24.686363636363637,
              28.136607142857137,
              26.65869565217391,
              25.8704918032787,
              33.00416666666666,
              24.0268115942029,
              29.797560975609763
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      NoOfRidesGraph: {
        labels: [
          "2023-01-07",
          "2023-01-08",
          "2023-01-09",
          "2023-01-10",
          "2023-01-11",
          "2023-01-12",
          "2023-01-13",
          "2023-01-14",
          "2023-01-15",
          "2023-01-16",
          "2023-01-17",
          "2023-01-18",
          "2023-01-19",
          "2023-01-20",
          "2023-01-21",
          "2023-01-22",
          "2023-01-23",
          "2023-01-24",
          "2023-01-25",
          "2023-01-26",
          "2023-01-27",
          "2023-01-28",
          "2023-01-29",
          "2023-01-30",
          "2023-01-31",
          "2023-02-01",
          "2023-02-02",
          "2023-02-03",
          "2023-02-04",
          "2023-02-05",
          "2023-02-06"
        ],
        datasets: [
          {
            label: "No fo Orders",
            data: [
              1,
              39,
              95,
              25,
              76,
              30,
              70,
              91,
              86,
              64,
              10,
              21,
              27,
              86,
              95,
              28,
              91,
              88,
              8,
              90,
              64,
              43,
              35,
              50,
              44,
              56,
              23,
              61,
              12,
              69,
              41
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      Co2Saved: {
        labels: [
          "2023-01-07",
          "2023-01-08",
          "2023-01-09",
          "2023-01-10",
          "2023-01-11",
          "2023-01-12",
          "2023-01-13",
          "2023-01-14",
          "2023-01-15",
          "2023-01-16",
          "2023-01-17",
          "2023-01-18",
          "2023-01-19",
          "2023-01-20",
          "2023-01-21",
          "2023-01-22",
          "2023-01-23",
          "2023-01-24",
          "2023-01-25",
          "2023-01-26",
          "2023-01-27",
          "2023-01-28",
          "2023-01-29",
          "2023-01-30",
          "2023-01-31",
          "2023-02-01",
          "2023-02-02",
          "2023-02-03",
          "2023-02-04",
          "2023-02-05",
          "2023-02-06"
        ],
        datasets: [
          {
            label: "Co2 Saved",
            data: [
              0.018719999999999997,
              2.6092299999999997,
              6.577090000000001,
              1.6681599999999994,
              4.9748399999999995,
              2.01474,
              4.85693,
              6.2696400000000025,
              6.654439999999999,
              3.477629999999999,
              0.84292,
              1.4597699999999998,
              1.7392700000000003,
              5.845449999999999,
              5.826209999999999,
              1.85354,
              5.867419999999997,
              5.804369999999999,
              0.37335999999999997,
              5.3904499999999995,
              3.6254399999999984,
              3.1807099999999995,
              1.84483,
              2.7847299999999997,
              2.8241199999999997,
              4.096689999999999,
              1.59419,
              4.10306,
              1.0297299999999998,
              4.31041,
              3.17642
            ],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)"
          }
        ]
      },
      FuelAndTreesGraph: {
        labels: [
          "2023-01-07",
          "2023-01-08",
          "2023-01-09",
          "2023-01-10",
          "2023-01-11",
          "2023-01-12",
          "2023-01-13",
          "2023-01-14",
          "2023-01-15",
          "2023-01-16",
          "2023-01-17",
          "2023-01-18",
          "2023-01-19",
          "2023-01-20",
          "2023-01-21",
          "2023-01-22",
          "2023-01-23",
          "2023-01-24",
          "2023-01-25",
          "2023-01-26",
          "2023-01-27",
          "2023-01-28",
          "2023-01-29",
          "2023-01-30",
          "2023-01-31",
          "2023-02-01",
          "2023-02-02",
          "2023-02-03",
          "2023-02-04",
          "2023-02-05",
          "2023-02-06"
        ],
        datasets: [
          {
            label: "Fuel Saved",
            data: [
              0.0576,
              8.028400000000003,
              20.237199999999998,
              5.1328,
              15.307199999999996,
              6.1992,
              14.9444,
              19.291200000000003,
              20.475200000000005,
              10.700400000000002,
              2.5936,
              4.491599999999999,
              5.351599999999999,
              17.986,
              17.926799999999997,
              5.7032,
              18.053600000000003,
              17.859600000000004,
              1.1487999999999998,
              16.586000000000006,
              11.155200000000002,
              9.7868,
              5.6764,
              8.5684,
              8.6896,
              12.605199999999998,
              4.9052,
              12.6248,
              3.1684000000000005,
              13.2628,
              9.7736
            ],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)"
          },
          {
            label: "Trees Relaxed",
            data: [
              0.17279999999999998,
              24.08519999999999,
              60.7116,
              15.3984,
              45.921599999999984,
              18.597599999999993,
              44.8332,
              57.873600000000046,
              61.4256,
              32.101200000000006,
              7.780799999999999,
              13.4748,
              16.0548,
              53.958000000000006,
              53.78040000000001,
              17.109600000000004,
              54.16079999999998,
              53.578800000000015,
              3.4464,
              49.758000000000024,
              33.465599999999995,
              29.360400000000002,
              17.0292,
              25.705199999999998,
              26.068799999999996,
              37.815599999999996,
              14.7156,
              37.87440000000001,
              9.5052,
              39.7884,
              29.320800000000006
            ],
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)"
          }
        ]
      },
      PeakDays: {
        labels: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        datasets: [
          {
            label: "No of Orders",
            data: [
              257,
              341,
              167,
              161,
              170,
              281,
              242
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      cardData: {
        totalCost: 41036.35,
        totalRides: 1619,
        totalDistance: 8207.269999999999,
        Num_of_users: 1379,
        totaCo2Saved: 106.69450999999995,
        totalFuelSaved: 328.29080000000033,
        totalTreesRelaxed: 98.48723999999987
      },
      LocationAndRevenue: {
        labels: [
          "Steinkjer",
          "Tromso",
          "Molde",
          "Drammen",
          "Ringsaker",
          "Bodo",
          "Halden",
          "Hamar",
          "Moss",
          "Skien",
          "Kristiansand",
          "Porsgrunn",
          "Hammerfest",
          "Fana",
          "Svolvær",
          "Stavanger",
          "Bergen",
          "Kabelvåg",
          "Sandefjord",
          "Haugesund"
        ],
        datasets: [
          {
            label: "Total Revenue",
            data: [
              1818.75,
              1807.4500000000003,
              1648.2999999999997,
              1588.8000000000002,
              1565.8500000000006,
              1558.1499999999999,
              1477.0500000000002,
              1476.6,
              1464.9499999999996,
              1427.9999999999998,
              1423.5999999999997,
              1413.6000000000001,
              1411.8000000000002,
              1361.4499999999996,
              1350.2000000000003,
              1334.1500000000003,
              1289.9000000000003,
              1260.1499999999996,
              1242.7499999999995,
              1203.3000000000002
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      LocationAndRides: {
        datasets: [
          {
            label: "Steinkjer",
            data: [
              {
                x: 69,
                y: 363.75,
                r: 25
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Tromso",
            data: [
              {
                x: 67,
                y: 361.49,
                r: 24.106366152629516
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Molde",
            data: [
              {
                x: 54,
                y: 329.6599999999999,
                r: 11.520363780150204
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Drammen",
            data: [
              {
                x: 66,
                y: 317.76,
                r: 6.814946619217054
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          },
          {
            label: "Ringsaker",
            data: [
              {
                x: 59,
                y: 313.17,
                r: 5
              }
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      },
      LocationAndUsers: {
        labels: [
          "Steinkjer",
          "Tromso",
          "Molde",
          "Drammen",
          "Ringsaker",
          "Bodo",
          "Halden",
          "Hamar",
          "Moss",
          "Skien",
          "Kristiansand",
          "Porsgrunn",
          "Hammerfest",
          "Fana",
          "Svolvær",
          "Stavanger",
          "Bergen",
          "Kabelvåg",
          "Sandefjord",
          "Haugesund"
        ],
        datasets: [
          {
            label: "Total Users",
            data: [
              68,
              67,
              54,
              66,
              59,
              59,
              59,
              67,
              61,
              52,
              56,
              53,
              56,
              50,
              52,
              51,
              44,
              49,
              50,
              45
            ],
            borderColor: borderColor,
            backgroundColor: backgroundColor,
          }
        ]
      }
    },
    CardTextColor:"rgba(114,46,209,1)",
    CardBackground:"rgba(139,80,221,0.6)",
    
  }




  export const DummyProfileData = {
    
      city: "Oslo",
      companyName: "ABC Company",
      companyType: "Information Technology",
      country: "Norway",
      email: "Jhon.doe@email.com",
      firstName: "Jhon",
      surName: "Doe1",
      jobTitle: "Software Engineer",
      contactNum: "+47 9550 7565",

  
  }