import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isErrorUS:false,
    messageUS:'',
}




// Get User userData
export const NewLetterSubscription = createAsyncThunk('NewsLetter/sendEmail', async(email, thunkAPI)=>{

    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/userData/newsletter`
        console.log(email)
        const response = await axios.post(API_URL, {email})
        console.log("Email added to Newsletter list")
        console.log(response.data)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})





export const NewLetterSlice = createSlice({
    name:'NewLetterSubscription',
    initialState,
    reducers:{
        reset: (state)=> initialState,
        resetMessge:(state)=>{
            state.messageUS = ''
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(NewLetterSubscription.fulfilled, (state, action)=>{
            state.messageUS = action.payload.message
        })
        .addCase(NewLetterSubscription.rejected, (state, action)=>{
            state.isErrorUS=true
            state.messageUS = action.payload
        })

    }
})


export const {reset,resetMessge} = NewLetterSlice.actions
export default NewLetterSlice.reducer