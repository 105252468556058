import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const width =  window.innerWidth
let height
if(width<=900){
   height = 250
}




export function BarChart({data, title, showTitle}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
          display:false,
        position: 'top',
      },
      title: {
        display: showTitle,
        text: title,
      },
  
    },
    maintainAspectRatio: false ,
    borderRadius:'50',
    
  };
  
  return <Bar options={options} data={data}   width={100} height={height}/>;
}
