import {TbMessageReport} from 'react-icons/tb'

const DemoDashboardHeader = () => {

  return (
    <section className='DashboardHeader'>
        <div className='notification_icon'>
        <div className='dot'>!</div>
        <TbMessageReport/>
        </div>
        <div className="avatar flex_row">J</div>
        <h6>Jhon Doe</h6>
    </section>
  )
}

export default DemoDashboardHeader