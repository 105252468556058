import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {logout} from '../Auth/AuthSlice'

const dashboard = JSON.parse(localStorage.getItem('dashboard'))

const initialState = {
    record:[],
    userDataR:[],
    isErrorR:false,
    isSuccessR:false,
    isLoadingR:false,
    messageR:'',

}




// Get User userDataR



export const getRecordedData = createAsyncThunk('RecordedData/getData', async(data, thunkAPI)=>{

    try{
        const {requiredDate:date, id:dashboardId} = data
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/records/requested`
        const token = thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        const response = await axios.post(API_URL,{date,dashboardId},config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        return thunkAPI.rejectWithValue(message)
    }
})


export const getAllRecords = createAsyncThunk('RecordedData/getAllrecodes', async(id, thunkAPI)=>{

    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/records/getAllRecords`
        const token = thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        const response = await axios.post(API_URL,{dashboardId:id},config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        return thunkAPI.rejectWithValue(message)
    }
})






export const RecordDataSlice = createSlice({
    name:'RecordedData',
    initialState,
    reducers:{
        resetRecord: (state)=> state = initialState,
        resetMessgeRecord:(state)=>{
            state.messageR = ''
        },

    },
    extraReducers:(builder)=>{
        builder

        .addCase(getRecordedData.pending, (state)=>{
            state.isLoadingR = true
            state.isErrorR=false
        })
        .addCase(getRecordedData.fulfilled, (state, action)=>{
            state.isLoadingR = false
            state.userDataR = action.payload.userData
            state.messageR  =action.payload.message
            if(action.payload.message==="Successful"){
                state.isSuccessR = true
            }
            state.isErrorR=false
        })
        .addCase(getRecordedData.rejected, (state, action)=>{
            state.isLoadingR = false
            state.isErrorR=true
            state.isSuccessR = false
            state.messageR = action.payload
            
        })


        .addCase(getAllRecords.pending, (state)=>{
            state.isLoadingR = true
        })
        .addCase(getAllRecords.fulfilled, (state, action)=>{
            state.isLoadingR = false
            state.messageR  =action.payload.message
            if(action.payload.message==="Successful"){
                state.record = action.payload.record
            }else{
                state.record = []
            }
            state.isErrorR=false
        })
        .addCase(getAllRecords.rejected, (state, action)=>{
            state.isLoadingR = false
            state.isErrorR=true
            state.messageR = action.payload
            
        })
    }
})


export const {reset,resetMessge,LoadLocalStorageDataRecord} = RecordDataSlice.actions
export default RecordDataSlice.reducer