import React ,{useState}from 'react'
import './MobileNav.css' ;
import {Link} from 'react-router-dom'
import { logout, resetAll } from '../../features/Auth/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { reset } from '../../features/userData/userDataSlice'
import { useLocation } from 'react-router-dom'

const MobileNav = ({url}) => {
    const [show, setShow] = useState(false)
    const [firstShow, setFirstShow] = useState(true)
    const location = useLocation()
    const {user} =  useSelector((store)=>store.auth)
    const id =  location.pathname.substring(location.pathname.lastIndexOf('/')+1)


    const showFunction =()=>{
      setFirstShow(false)
      setShow(!show)
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const logoutSideBar =()=>{
      dispatch(logout())
      .then(dispatch(resetAll()))
      .then(dispatch(reset()))
      .then(navigate('/')) 
      
    }
  return (
    <div className="Mob_Navbar">
            <div className={show?"Mob_Navbar_right":(firstShow===false && show===false)?'hidden_animation':"hidee"}>
            <Link  to='/' onClick={showFunction}>Home</Link>
            <Link  to={`/${url}/mydashboard/${id}`} onClick={showFunction}>Dashboard</Link>
            <Link  to={`/${url}/profile/${id}`} onClick={showFunction}>Profile</Link>
            <Link  to={`/${url}/imapctAnalysis/${id}`} onClick={showFunction}>Imapct</Link>
            <Link to={`/${url}/pastDetails/${id}`} onClick={showFunction}>Records</Link>
            <Link to={`/${url}/customiseDashboard/${id}`} onClick={showFunction}>Settings</Link>
            {user ?<div style={{cursor:"pointer"}} onClick={()=>logoutSideBar()}>Logout</div>:
            <Link to={`/login`} onClick={showFunction}>Login</Link>
            }
        </div>
        <div className={show?'cross burger':'burger'} onClick={showFunction}>
            <div></div>
            <div></div>
        </div>
       
    </div>
  )
}

export default MobileNav

