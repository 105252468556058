import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {toast} from 'react-toastify'
import {ForgotPasswordFunction , reset } from '../../features/ForgotPassword/ForgotPasswordSlice'
import Spinner from '../../components/Spinner/Spinner'
import GoBackButton from '../../components/GoBackButton/GoBackButton'

const ForgotPassword = () => {
    const { isErrorF,messageF,isLoadingF,isSuccessF} =  useSelector((store)=>store.ForgotPassword)
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")

        const onSubmit =(e)=>{
            e.preventDefault()
            if(email===''){
                toast.info('Please provide your email')
            }else{
                dispatch(ForgotPasswordFunction(email))
            }
        }

        useEffect(()=>{
            if(isErrorF){
                toast.error(messageF)
            }
            if(isSuccessF){
                toast.info(messageF)
            }
            dispatch(reset())
        },[isErrorF, isSuccessF,messageF, dispatch])

    if(isLoadingF){
            return (
                <Spinner/>
            )
        }

  return (
    <section className='container' data-aos="slide-right">

        <div className='left'>
            <GoBackButton/>
            <h3 >Forgot Password ?</h3>
            <p >Resume your journey with us! </p>
        </div>

        <div className='right'>
            <div className='right_heading'>
            <h5>Hello There!</h5>
            <h6>Provide your Email address</h6>
            </div>
        <form onSubmit={onSubmit} className='form'>
            <div className="form_group">
                <input type="email" id='email' name='email' value={email} placeholder='Enter Your Email' onChange={(e)=>setEmail(e.target.value)}/>
            </div>
            <div className="form_group">
                <button type='submit' className='secondary_btn'>Submit</button>
            </div>
        <Link className='user_link'  to='/login'>Login to your account !</Link>
        </form>
        </div>
    </section>
  )
}
 
export default ForgotPassword