import React from 'react'
import './SideBar.css'
import {Link} from 'react-router-dom'
import {BiLogOut} from 'react-icons/bi'
import {RiApps2Line} from 'react-icons/ri'
import {CgProfile} from 'react-icons/cg'
import {AiOutlineSetting} from 'react-icons/ai'
import {TbDatabaseExport} from 'react-icons/tb'
import {SiGoogleanalytics} from 'react-icons/si'
import { useState } from 'react'
import { logout,resetAll } from '../../features/Auth/AuthSlice'
import { reset } from '../../features/userData/userDataSlice'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useDispatch } from "react-redux";
import {logo_complete} from '../../assets/SVG'


const SideBar = () => {
    const [activeNav, setActiveNav] = useState('dashboard')
    const location = useLocation()
    const id =  location.pathname.substring(location.pathname.lastIndexOf('/')+1)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    


    const logoutSideBar =()=>{
      dispatch(logout())
      .then(dispatch(resetAll()))
      .then(dispatch(reset()))
      .then(navigate('/')) 
      
    }


  return (

    <section className="sidebar">

   <Link to='/' className='logo_complete'>{logo_complete}</Link>
        <div className="sidebar_button_container" >
       
            <Link onClick={()=>setActiveNav('dashboard')} className={activeNav==='dashboard' ? 'sidebar_btn_active':'sidebar_btn_deactive'}  to={`/analysis/mydashboard/${id}`}><RiApps2Line/> Dashboard</Link>
            <Link onClick={()=>setActiveNav('profile')} className={activeNav==='profile' ? 'sidebar_btn_active':'sidebar_btn_deactive'} to={`/analysis/profile/${id}`}><CgProfile/>My Profile</Link>
            <Link onClick={()=>setActiveNav('impact')} className={activeNav==='impact' ? 'sidebar_btn_active':'sidebar_btn_deactive'} to={`/analysis/imapctAnalysis/${id}`}><SiGoogleanalytics/>Imapct Analysis</Link>
            <Link onClick={()=>setActiveNav('previous')} className={activeNav==='previous' ? 'sidebar_btn_active':'sidebar_btn_deactive'} to={`/analysis/pastDetails/${id}`}><TbDatabaseExport />Previous Data</Link>
            <Link onClick={()=>setActiveNav('customise')} className={activeNav==='customise' ? 'sidebar_btn_active':'sidebar_btn_deactive'} to={`/analysis/customiseDashboard/${id}`}><AiOutlineSetting />Settings</Link>
        </div>
        <div onClick={()=>logoutSideBar()}><BiLogOut/> Log out</div>

    </section>
  )
}

export default SideBar
