import React, { useEffect } from 'react'
import "./ConnectionTypeInfo.css"
import { useLocation } from "react-router-dom";
import GoBackButton from '../../components/GoBackButton/GoBackButton';
import StepComp from '../../components/StepComp/StepComp'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {toast } from 'react-toastify';


import {OnPromisesData,DashboardReport,impactReport,DashboardData,ImpactData} from '../../assets/SVG'
const ConnectionTypeInfo = () => {
    const location  = useLocation();
    const connectionType = location.pathname.substring(location.pathname.lastIndexOf('/')+1)

    const navigate= useNavigate()
    const {user} = useSelector((store)=>store.auth)

    useEffect(()=>{
        if(!user){
          toast.info("please Login first")
          navigate('/login')
        }
      },[user, navigate])
  return (
    <section className='connectionTypeInfo'>
        {connectionType==="OnPremisesDashbaord" ?
            <ConnectionTypeComp data={OnPromisesData} />:
        connectionType==="DashboardReport" ?
            <ConnectionTypeComp data={DashboardReport} />:
        connectionType==="impactReport" ?
            <ConnectionTypeComp data={impactReport} />:
        connectionType==="DashboardData" ?
            <ConnectionTypeComp data={DashboardData} />:
        connectionType==="ImpactData" ?
            <ConnectionTypeComp data={ImpactData} />:
        <p>Wrong connection Type</p>
        }
    </section>
  )
}

export default ConnectionTypeInfo


const ConnectionTypeComp = ({data: {heading,detail, features, steps,connectionUrl}}) => {
  return (
    <section className='connectionTypeComp'>

        <div className="ConnectHeading">
        <GoBackButton/>
            <h3>{heading}</h3>
        </div>

        <div className="info_section compFlex">
        <div className="highlight_section">
            {features.map((item)=>{
                return(
                    <div className="highlights">
                    {item.svg}
                    <h4>{item.cardHeading}</h4>
                    <p>{item.cardDescription}</p>
                </div>
                )
            })
        }
        </div>
        <div className="infoSectionDetain">
            <h4>Details</h4>
            <p>{detail}</p>
        </div>
        <div className="infoSectionDetain">
            <h4>Connection Steps</h4>
            <StepComp steps={steps} connectionUrl={connectionUrl}/>
        </div>
        </div>

      
    </section>
  )
}
