import React ,{useState}from 'react'
import {Link} from 'react-router-dom'

const DemoMobileNav = () => {
    const [show, setShow] = useState(false)
    const [firstShow, setFirstShow] = useState(true)

    const showFunction =()=>{
      setFirstShow(false)
      setShow(!show)
    }

  return (
    <div className="Mob_Navbar">
            <div className={show?"Mob_Navbar_right":(firstShow===false && show===false)?'hidden_animation':"hidee"}>
            <Link  to='/' onClick={showFunction}>Home</Link>
            <Link  to={`/viewDemo/example`} onClick={showFunction}>Dashboard</Link>
            <Link  to={`/viewDemo/profile/`} onClick={showFunction}>Profile</Link>
            <Link  to={`/viewDemo/imapctAnalysis/`} onClick={showFunction}>Imapct</Link>
            <Link to={`/viewDemo/pastDetails/`} onClick={showFunction}>Records</Link>
            <Link to={`/viewDemo/customiseDashboard/`} onClick={showFunction}>Settings</Link>
            <Link to={`/login`} onClick={showFunction}>Login</Link>
        </div>
        <div className={show?'cross burger':'burger'} onClick={showFunction}>
            <div></div>
            <div></div>
        </div>
       
    </div>
  )
}

export default DemoMobileNav

