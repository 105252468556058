import React ,{useState}from 'react'
import {Link} from 'react-router-dom'
import { logout,resetAll } from '../../../features/Auth/AuthSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { reset } from '../../../features/userData/userDataSlice'

import './HomeSideBar.css'
const MobileNav = ({url}) => {
    const [show, setShow] = useState(false)
    const [firstShow, setFirstShow] = useState(true)
    const {user} =  useSelector((store)=>store.auth)

    const showFunction =()=>{
      setFirstShow(false)
      setShow(!show)
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const logoutSideBar =()=>{
      dispatch(logout())
      .then(dispatch(resetAll()))
      .then(dispatch(reset()))
      .then(navigate('/')) 
      setShow(!show)
      
    }
  return (
    <div className="Mob_Navbar">
            <div className={show?"Mob_Navbar_right":(firstShow===false && show===false)?'hidden_animation':"hidee"}>
            <Link  to={`/dashboards`} onClick={showFunction}>Dashboard</Link>
            <Link  to={`/viewDemo/example`} onClick={showFunction}>Demo</Link>
            <Link  to={`/register`} onClick={showFunction}>Signup</Link>
            {/* {user && user.userType==="normal_user" && <Link  to={`/subscriptions`} onClick={showFunction}>Subscriptions</Link>} */}
            <Link to={`/contactUs`} onClick={showFunction}>Contact</Link>
            {user ?<div style={{cursor:"pointer"}} onClick={()=>logoutSideBar()}>Logout</div>:
            <Link to={`/login/`} onClick={showFunction}>Login</Link>}
        </div>
        <div className={show?'crossH burgerH':'burgerH'} onClick={showFunction}>
            <div></div>
            <div></div>
        </div>
       
    </div>
  )
}

export default MobileNav