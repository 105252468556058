import React from 'react'
import './ContactUs.css'
import {BiSend} from 'react-icons/bi'
import { useRef, useState} from 'react';
import emailjs from 'emailjs-com';
import {toast} from "react-toastify";
import Header from '../../components/Header/Header'
import {MdOutlineWeb} from 'react-icons/md'
import {SiMinutemailer} from 'react-icons/si'
import {FiInstagram} from 'react-icons/fi'
import {BsLinkedin} from 'react-icons/bs'
import { Link } from 'react-router-dom'
import AOS from 'aos';

import {MdKeyboardBackspace} from 'react-icons/md'
import GoBackButton from '../../components/GoBackButton/GoBackButton';



const ContactUs = () => {
  AOS.init({offset: 400, 
    delay: 10});


  const form = useRef()
  const [input, setInput] = useState({
    name:'',
    email:'',
    message:''
  })



    const fillDetails=(e)=>{
          const inputName=e.target.name;
          const inputValue=e.target.value;
          setInput({...input,[inputName]:inputValue})
      }



  const sendEmail = (e) => {
    e.preventDefault();
    if(input.name!=='' && input.email!=='' && input.message!==''){
        emailjs.sendForm('service_oq9ubr8', 'template_8pxgwx8', form.current, 'NKZ6rJ2TPd2TYecM3') //fori
        // emailjs.sendForm('service_1dfhyni', 'template_fs9nue9', form.current, 'kBxhbgyOTkvwm01I-')
        .then((result)=>{
          toast.info(result.status===200 &&'Message Sent')
          setInput({
              name:'',
              email:'',
              message:''
            })
        })
        .catch((error)=>{
          toast.info(error.status===0 &&'Network Issue')
        })

   }
      else{
        toast.error('Please fill the required fields')
      }
  };



  return (


    <div className='container' data-aos="slide-right">
    <div className='left' >
      <GoBackButton/>

        <h3>Contact Information</h3>
        <p>Any question or remarks? Just write us a message!</p>
            
        <div className='ContactSocial'><SiMinutemailer />info@fori.co</div>
        <div className='social'>
                <a href="https://fori.co/" target="_blank"  rel="noreferrer" ><MdOutlineWeb/></a> 
                <a href="https://www.instagram.com/fori_inc/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer" ><BsLinkedin /></a> 
                <a href="https://www.linkedin.com/company/foriinc/mycompany/" target="_blank"  rel="noreferrer" ><FiInstagram/></a> 
        </div>
    </div>
    
    <div className='right' >
        <form className='form ContactFormDiv' ref={form}>
            <div className="form_groupContact">
            <input name="name" type="text" placeholder='Enter your name' value={input.name} onChange={fillDetails} required />
            </div>

            <div className="form_groupContact">
            <input type="email" name='email' placeholder='Enter your email' value={input.email} onChange={fillDetails} required />
            </div>

            <div className="form_groupContact">
            <textarea name="message" type="text"  placeholder='Type your message' rows="3" value={input.message} onChange={fillDetails} required />
            </div>
            <button type='submit' className='secondary_btn'  onClick={sendEmail}>Send</button>
        </form>
        </div>

    </div>



  )
}

export default ContactUs