import {useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMultipleDashboard ,resetDashboard,deleteDashboard} from "../../features/multipleDashboard/multipleDasboard";
import {requiredDashboard} from "../../features/userData/userDataSlice"
import Spinner from "../../components/Spinner/Spinner";
import { toast } from "react-toastify";
import { Link ,useNavigate} from "react-router-dom";
import './DashboardNamePage.css'
import {MdOutlineDelete} from 'react-icons/md'
import GoBackButton from "../../components/GoBackButton/GoBackButton";

const DashboardName = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const {dashboardsNames, isSuccessMD, isErrorMD, isLoadingMD, messageMD } =useSelector((store) => store.MultipleDasboard);
    const {user}=useSelector((store)=>store.auth)

console.log(dashboardsNames,"names")
useEffect(()=>{
  if(user){
    dispatch(getMultipleDashboard())
  }else{
    navigate('/login')
  }
},[user,navigate,dispatch])

  useEffect(() => {
    if (isErrorMD) {
      toast.error(messageMD);
    }
    // return ()=>dispatch(resetDashboard())

  }, [isLoadingMD]);


  if(isLoadingMD===true){
    console.log("ksksksk")
    return <Spinner/>
  }
  
    return(
      <>{
        dashboardsNames.length===0?
        <div>
        <div className="ConnectHeading">
          <GoBackButton/>
          <h3>Let's Setup</h3>
        </div>

        <div className="no_dashboard">
          <div>
            <h3>No dashboard created yet</h3>
            <p>Please click the button below to setup your first dashboard</p>
          </div>

          <div className="intruction_top_left_btn_container">
            <Link to="/connect" className="secondary_btn">Add Dashboard +</Link>
          </div>
       </div>

      </div>:
       <section>
       <div className="ConnectHeading">
         <GoBackButton/>
       <h3>Choose your dashboard</h3>
     </div>

     <div className="no_dashboard">

       <div>
         <h3>your's Dashboards</h3>
         <p>Select a Dashboard to countinue</p>
       </div>

       <div className="dashboard_names">
           {dashboardsNames.map((item, i) => {
             return (
               <div className="flex_row_gap" key={i} >
                 <button onClick={()=>dispatch(requiredDashboard({dashboardId:item._id})).then((a)=>{if(a.payload.success===true){navigate(`/analysis/mydashboard/${item._id}`)}})}
                   className="btn less_padding"
                   value={item._id}
                 >
                   {item.DashboardName.slice(0,20)}
                 </button>
                 <button onClick={()=>dispatch(deleteDashboard({dashboardId:item._id,DashboardName:item.DashboardName})).then((a)=>{if(a.payload.success===true){dispatch(getMultipleDashboard())}})}><MdOutlineDelete className="icon_react"/></button>
               </div>
             );
           })}
       </div>
     
       <div className="intruction_top_left_btn_container">
           <Link to="/adddashboard" className="secondary_btn less_padding">Add Dashboard +</Link>
       </div>
     </div>
   </section>

      }
      </>
      )

};

export default DashboardName;
