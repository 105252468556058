import React from 'react'
import './Login.css'
import {MdKeyboardBackspace} from 'react-icons/md'
import {VscEye} from 'react-icons/vsc'
import {GrFormViewHide} from 'react-icons/gr'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { login, reset } from '../../features/Auth/AuthSlice'
import Spinner from '../../components/Spinner/Spinner'
import AOS from 'aos';

const Login = () => {
    AOS.init({offset: 300, 
        delay: 10});
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {user, isLoading, isError, isSuccess, message} = useSelector((store)=>store.auth)
    const [passShow, setPassShow] = useState(false)


    const [formData, setFormData] = useState({
        email:'',
        password:'',
        })

        const {email, password} = formData


        const onChange =(e)=>{
            setFormData((prevState)=>({...prevState, [e.target.name]:e.target.value}))
    
        }
        const onSubmit =(e)=>{
            e.preventDefault()
            if(email==='' || password===''){
                toast.info('Please fill both fields')
            }else if(email!=='' && password!==''){
                const userData = {email:email.toLowerCase(), password:password}
                dispatch(login(userData))
            }
        }

        useEffect(()=>{
            if(isError){
                toast.error(message)
            }
            if(isSuccess){
                navigate('/')
            }
            dispatch(reset())
        },[user, isError, isSuccess,message, dispatch, navigate])

    if(isLoading){
            return (
                <Spinner/>
            )
        }

  return (
    <section className='container' data-aos="slide-right">

        <div className='left'>
        <Link to='/'><MdKeyboardBackspace className='back_button'/></Link>
            <h3>Sign In to Dashboard</h3>
            <p>Monitor your business on realtime dashboard</p>
        </div>

        <div className='right'>
            <div className='right_heading'>
            <h5>Hello Again!</h5>
            <h6>Welcome back</h6>
            </div>
        <form onSubmit={onSubmit} className='form'>
            <div className="form_group">
                <input type="email" id='email' name='email' value={email} placeholder='Enter Your Email' onChange={onChange}/>
            </div>
            <div className="form_group password_div">
                <input type={passShow===true?"text":"password"}  id='password' name='password' value={password} placeholder='Enter Your password' onChange={onChange}/>
                {passShow===true?<VscEye onClick={()=>setPassShow(!passShow)}/>:<GrFormViewHide onClick={()=>setPassShow(!passShow)}/>}
            </div>
            <div className="form_group">
                <button type='submit' className='secondary_btn'>Login</button>
            </div>
        <Link to='/forgot-Password' className='user_link'>Forgot Password ?</Link>
        </form>
        <p>Don’t have an account? <Link className='special_a_tag' to='/register'>Sign Up</Link> here</p>
        </div>
    </section>
  )
}
 
export default Login