import React from 'react'
import './ImpactCard.css'
import {ImpactSvg} from '../../assets/SVG'

const ImpactCard = (props) => {

  
  let SUFFIXES = 'KMBTqQsSOND' 
    const {heading, amount, backgroundColor,color,svg, unit}  =props
    function getSuffixedNumber(num) {
      console.log(amount)
      var power = Math.floor(Math.log10(num));
      var index = Math.floor(power / 3);
      num = Math.round(num / Math.pow(10, (index * 3))); // first 3 digits of the number
      return num + (SUFFIXES[index - 1] || ''); // default to no suffix if we get an out of bounds index
  }
  return (
    <div className='ImpactCard card' style={{backgroundColor:backgroundColor, color:color}}>
        {svg==='green'?ImpactSvg.GreenSvg:ImpactSvg.PurpleSvg}

        <h4>{heading}</h4>
        <h3>{getSuffixedNumber(amount)} {unit}</h3>
    </div>
  )
}

export default ImpactCard