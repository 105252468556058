import feature1 from '../../../assets/feature1.png'
import feature2 from '../../../assets/feature2.png'
import { About_svg } from '../../../assets/SVG'
import './About.css'
const About = () => {
  return (
    <section id='about' className='about'>

      <div className="feature" data-aos="zoom-in" data-aos-offset="200">
        <div className='feature_img'>
      <img src={feature1} alt="" />
        </div>
        <div className="feature_highlights">

        <div className="bullet">
              {About_svg.svg1}
              <p>For Engineering Teams</p>
        </div>
        <h3>Keeps a record of the past data</h3>
        <p>Fetch past data records from database by selecting the date of the previously retrieved data on the calendar to overview your company’s progress overtime.</p>

        </div>

      </div>


      <div className="feature feature2" data-aos="zoom-in" data-aos-offset="200">
        
      <div className="feature_highlights">
      <div className="bullet">
              {About_svg.svg2}
              <p>For Sustainable Teams</p>
        </div>
        <h3>Impact analysis</h3>
        <p>The first step in your commitment to the climate is to measure your company's CO2 emissions by providing key data for our algorithm to calculate your footprint.</p>
      </div>

      <div className="feature_img">
      <img src={feature2} alt="" />
      </div>

      </div>
    </section>
  )
}

export default About