import React from 'react'
import { BarChart } from '../../components/ChartComponents/BarChart/BarChart'
import { LineChart } from '../../components/ChartComponents/LineChart/LineChart'
import { AreaChart } from '../../components/ChartComponents/AreaChart/AreaChart'
import { HorizontalBarChart } from '../../components/ChartComponents/HorizontalBarChart/HorizontalBarChart'
import Card from '../../components/Card/Card'
import { useState } from 'react'
import { BubbleChart } from '../../components/ChartComponents/BubbleChart/BubbleChart'
import {TbRefresh} from 'react-icons/tb'
import {toast} from 'react-toastify'
import {DummyData} from '../../assets/DummyData'
import '../AnalyticalDashboard/AnalyticalDashboard.css'
import InfoBox from '../../components/InfoBox/InfoBox'


const DemoAnalyticalDashboard = () => {
  const [activeFilter, setActiveFilter ] = useState('days30')
  const [filter,setFilter] = useState('days30')
  const [show, setShow] = useState({ graph1:false, graph2:false, graph3:false, graph4:false, graph5:false, graph6:false, graph7:false,})




  const refreshData =()=>{
    toast.info('This is a demo page, Connect your dashboard now!')
  }



  const ChoseFilter = (filter) =>{
    setActiveFilter(filter)
    setFilter(filter)
  }




  const {year,month12,days30,CardTextColor,CardBackground}=DummyData
  let data = filter==='days30'?days30:filter==='month12'?month12:year

  return (
    <div className='dashboard_container'>
    <div className="date_control">
      <h5 className='dash_heading'>Dashobaord</h5>



      <div className='control_buttons'>
        <div className='RefreshData btn' onClick={refreshData}>
          Refresh Data
        <TbRefresh/>
        </div>
          <button  onClick={()=>ChoseFilter('year')} className={activeFilter==='year' ? 'activeFilter':'deactive_filter'}>Yearly</button>
          <button  onClick={()=>ChoseFilter('month12')} className={activeFilter==='month12' ? 'activeFilter':'deactive_filter'}>Last 12 Months</button>
          <button  onClick={()=>ChoseFilter('days30')} className={activeFilter==='days30' ? 'activeFilter':'deactive_filter'}>Last 30 Days</button>
      </div>  
    </div>
    
    <div className="dashboard_box card_Box">
       <Card heading='Total Revenue' amount={data.cardData.totalCost} color={{CardTextColor,CardBackground}}/>
       <Card heading='Average Revenue' amount={data.cardData.totalCost/data.cardData.totalRides} color={{CardTextColor,CardBackground}}/>
       <Card heading='Total Rides' amount={data.cardData.totalRides} color={{CardTextColor,CardBackground}}/>
       <Card heading='Users' amount={data.cardData.Num_of_users} color={{CardTextColor,CardBackground}}/>
      </div>  

      {/* //Graph1 */}
      <div className="dashboard_box row1">
        <div className="graph1_info">
        <h5 className='dash_heading'>Total Revenue</h5>
        <h3 className='dash_number'>{`$ ${data.cardData.totalCost.toFixed(0)}`}</h3>
        </div>
      
        <div className="inner_graph_box">
        <InfoBox show={show} setShow={setShow} graph="graph1" info={`Revenue graph is visual representation of the amount of money generated by your business ${filter==="days30"?"in last 30 days of your data.":filter==="month12"?"in last 12 months of your data.":"over years"} `}/>

        <BarChart data={data.TotalRevenueGraph} title='Revenue Analysis' showTitle={false} />

        </div>
      </div> 

      {/* Graph2 */}

      <div className="row2">
        <div className="left_box">
        <InfoBox show={show} setShow={setShow} graph="graph2" info={`Order analysis graph represents the amount of orders received by your business ${filter==="days30"?"in last 30 days of your data.":filter==="month12"?"in last 12 months of your data.":"over years"} `}/>

        <AreaChart data={data.NoOfRidesGraph} title='Total Orders Received' showTitle={true}/>
        </div>
        <div className="right_box">
        <InfoBox show={show} setShow={setShow} graph="graph3" info={`This is visual representation of your business peak day with respect to the volume of orders ${filter==="days30"?"in last 30 days of your data.":filter==="month12"?"in last 12 months of your data.":"over years"} `}/>

        <HorizontalBarChart data={data.PeakDays} title='Peak Day Analysis' showTitle={true}/>
        </div>
      </div>

            {/* Graph3 */}

      <div className="row2">
        <div className="left_box">
        <InfoBox show={show} setShow={setShow} graph="graph4" info={`This represents your peak buisness locations from where most of the orders are received ${filter==="days30"?"in last 30 days of your data.":filter==="month12"?"in last 12 months of your data.":"over years"} `}/>

        <BarChart data={data.LocationAndRevenue} title='Buisness Performance over Different Locations' showTitle={true}/>
        </div>
        <div className="right_box">
        <InfoBox show={show} setShow={setShow} graph="graph5" info={`This graph shows main buisness locations ${filter==="days30"?"in last 30 days of your data.":filter==="month12"?"in last 12 months of your data.":"over years"}, First parameter is number of users, second is total driven kilometers and the last is average revenue generated.`}/>

        <BubbleChart data={data.LocationAndRides} title='Buisness Performance over Different Locations' showTitle={true}/>
        </div>
      </div>

      <div className="row2">
        <div className="left_box">
        <InfoBox show={show} setShow={setShow} graph="graph6" info={`This represents your peak buisness locations, where most of your users are located ${filter==="days30"?"in last 30 days of your data.":filter==="month12"?"in last 12 months of your data.":"over years"} `}/>

        <BarChart data={data.LocationAndUsers}  title='Total Users over Different Locations' showTitle={true}/>
        </div>
        <div className="right_box">
        <InfoBox show={show} setShow={setShow} graph="graph7" info={`This represents your average revenue with respect to number of rides ${filter==="days30"?"in last 30 days of your data.":filter==="month12"?"in last 12 months of your data.":"over years"} `} />

        <LineChart data={data.AverageRevenueGraph}  title='Average Revenue Analysis' showTitle={true}/>
        </div>
      </div>
      </div>
  )
}

export default DemoAnalyticalDashboard
