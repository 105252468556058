import './Footer.css'
import {NewLetterSubscription, resetMessge} from '../../../features/NewsLetter/NewsLetter'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'
import {RiLinkedinFill} from 'react-icons/ri'
import {TbWorld} from 'react-icons/tb'
import {TbBrandInstagram} from 'react-icons/tb'
import {logo_complete} from "../../../assets/SVG"

const Footer = () => {
    const [email, setEmail] = useState('')
    const {messageUS} = useSelector((store)=>store.NewsLetter)

    function NewsLetter (e){
        e.preventDefault()
        if(email!==''){
            dispatch(NewLetterSubscription(email))
        }else{
            toast.error('Please Provide valid Email')
        }
    }
    const dispatch = useDispatch()

   useEffect(()=>{
    if(messageUS){
        toast.info(messageUS)
        dispatch(resetMessge())
    }
    // return(()=>{
    //     dispatch(reset())
    // })
   },[messageUS, dispatch])
  return (
    <section className='footer'>
      
       
        <div className="footer_left" data-aos="slide-right" data-aos-offset="200">
            {logo_complete}
            <p>With a team of highly skilled professionals and a commitment to excellence, FORI is a leading in industry and offering innovative solutions and exceptional service to our clients</p>
            <div className='social'>
                <a href="https://fori.co/" target="_blank" rel="noreferrer"><TbWorld/></a> 
                <a href="https://www.instagram.com/fori_inc/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noreferrer"><TbBrandInstagram /></a> 
                <a href="https://www.linkedin.com/company/foriinc/mycompany/" target="_blank" rel="noreferrer"><RiLinkedinFill/></a> 
            </div>
        </div>
        <div className="footer_right" data-aos="slide-left" data-aos-offset="200">
                <div className="footer_right_top">
                <h6>subscribe to our newsletter</h6>
                <form className='email_column'>
                <input className='enailInput' type="email" placeholder='Email' onChange={(e)=>setEmail(e.target.value)}/>
                <button className='secondary_btn borderless send' onClick={NewsLetter}>send</button>
                </form>
                </div>
                <div className="footer_right_bottom">
                <Link to="/">Connection</Link>
                <Link to="/">Integrations</Link>
                <Link to="/">Pricing</Link>
                <Link to="/">API</Link>
                <Link to="/">Careers</Link>
                <Link to="/">Help Center</Link>
                <Link to="/contactUs">Contact us</Link>
                <Link to="/">Documentation</Link>
                <Link to="/">Partners</Link>
                </div>
        </div>

    </section>
  )
}

export default Footer
