import { useSelector,useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from "react";
import {createMultipleDashboard,resetDashboard} from "../../features/multipleDashboard/multipleDasboard";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import {RiArrowGoBackLine} from 'react-icons/ri'


const DataForm = () => {
    const navigate= useNavigate()
    const dispatch=useDispatch()
    const {user} = useSelector((store)=>store.auth)
    const {messageMD,isSuccessMD,isLoading} = useSelector((store)=>store.MultipleDasboard)

    const [data,setData]=useState({url:"",curency:"",secretKey:"", DashboardName:"", DashboardColor:'#722ED1'})
    const{url,curency,secretKey,DashboardName,DashboardColor}=data;

    // onchange function for inputs
    function inputValueupdate(e){
        e.preventDefault();
        setData({...data,[e.target.name]:e.target.value})
    }
    // inputValueupdate ends here

    // handleSubmit starts here
    function handleSubmit(e){
        e.preventDefault();
        // regex for url
        // var expression =/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        // var regex = new RegExp(expression);
        // check if any field is field
        if(!url || !curency || !secretKey || !DashboardName || !DashboardColor){
            toast.error("Please fill all fields")
        }else{
            // checking if url is valid
            if (true) {   //url.match(regex)
                // checking if currency is valid
                let validcurr=false;
                 var cur_arr=document.getElementById('currencysuggestion').children;
                 for (let i = 0; i < cur_arr.length; i++){
                    if(curency===cur_arr.item(i).value){
                        validcurr=true
                    }
                 }
                 if(validcurr){
                    // valid data which can be posted to backened
                      dispatch(createMultipleDashboard(data)).then((a,b)=>{
                        console.log(a.payload.sucsess,"sucess")
                        if(a.payload.success===true){navigate("/dashboards")}
                        dispatch(resetDashboard())})
                 }else{
                    toast.error("Please provide a valid curency")
                 }
             } else {
                toast.error("Please provide a valid url");
             }
        }
    }
 

    useEffect(()=>{
      if(!user){
        navigate('/login')
      }

    },[user, navigate])

    useEffect(()=>{
      if(messageMD && isLoading===false){
        toast.info(messageMD)
      }

    },[isLoading,messageMD])
    
  return (
      <form className="ConnectContainer">

        <div className="ConnectHeading">
        <Link to='/'><RiArrowGoBackLine className='back_button'/></Link>

        <h1 className="logo_icon">Fori Analytics</h1>
        <h3>Form to connect your dashboard</h3>
        </div>
      
        <div className="FormChild">
          <label htmlFor="url" >Server Url</label>
          <input type="url" id="url" required name="url" placeholder="Enter URL" value={url} onChange={inputValueupdate}/>
        </div>
        <div className="FormChild">
          <label htmlFor="curency">Choose your curency</label>
          <Currency data={data} setData={setData} inputValueupdate={inputValueupdate}/>
        </div>
        <div className="FormChild">
          <label htmlFor="secretKey">Secret Key</label>
          <input type="text" id="secretKey"name="secretKey" placeholder="Enter Secret Key" value={secretKey} onChange={inputValueupdate}/>
        </div>
        <div className="FormChild">
          <label htmlFor="DashboardName">Dashboard Name</label>
          <input type="text" id="DashboardName"name="DashboardName" placeholder="Enter Dashboard Name" value={DashboardName} onChange={inputValueupdate}/>
        </div>
        <div className="FormChild">
        <label htmlFor="DashboardName">Please Select Your Favourite Color</label>
          <input className="colorpicker" type="color" id="DashboardColor"name="DashboardColor" placeholder="Enter Dashboard Color" value={DashboardColor} onChange={inputValueupdate}/>
        </div>
        <div className="FormChild FormChildBtn">
            <button className="btn" name="submitbtn" type="submit" onClick={handleSubmit}>Submit</button>
        </div>
      </form>
   
  );
};

function Currency(props) {
  return (
    <>
      <input list="currencysuggestion" id="curency" name="curency" value={props.data.currency} onChange={props.inputValueupdate} placeholder="Enter Currency"/>
      <datalist id="currencysuggestion" name="currencysuggestion">
        <option value="Lek">Albanian Lek</option>
        <option value="؋">Afghan Afghani</option>
        <option value="$">Argentina Peso</option>
        <option value="$">Australia Dollar</option>
        <option value="₼">Azerbaijan Manat</option>
        <option value="$">Bahamas Dollar</option>
        <option value="$">Barbados Dollar</option>
        <option value="Br">Belarus Ruble</option>
        <option value="BZ$">Belize Dollar</option>
        <option value="$">Bermuda Dollar</option>
        <option value="$b">Bolivia Bolíviano</option>
        <option value="KM">Bosnia and Herzegovina Convertible Mark</option>
        <option value="P">Botswana Pula</option>
        <option value="лв">Bulgaria Lev</option>
        <option value="R$">Brazil Real</option>
        <option value="$">Brunei Darussalam Dollar</option>
        <option value="៛">Cambodia Riel</option>
        <option value="$">Canada Dollar</option>
        <option value="$">Cayman Islands Dollar</option>
        <option value="$">Chile Peso</option>
        <option value="¥">China Yuan Renminbi </option>
        <option value="$">Colombia Peso</option>
        <option value="₡">Costa Rica Colon</option>
        <option value="kn">Croatia Kuna</option>
        <option value="₱">Cuba Peso</option>
        <option value="Kč">Czech Republic Koruna</option>
        <option value="kr">Denmark Krone</option>
        <option value="RD$">Dominican Republic Peso</option>
        <option value="$">East Caribbean Dollar</option>
        <option value="£">Egypt Pound</option>
        <option value="$">El Salvador Colon</option>
        <option value="€">Euro Member Countries </option>
        <option value="£">Falkland Islands (Malvinas) Pound </option>
        <option value="$">Fiji Dollar </option>
        <option value="¢">Ghana Cedi</option>
        <option value="£">Gibraltar Pound </option>
        <option value="Q">Guatemala Quetzal</option>
        <option value="£">Guernsey Pound</option>
        <option value="$">Guyana Dollar</option>
        <option value="L">Honduras Lempira</option>
        <option value="$">Hong Kong Dollar</option>
        <option value="Ft">Hungary Forint</option>
        <option value="kr">Iceland Krona</option>
        <option value="Rp">Indonesia Rupiah</option>
        <option value="﷼">Iran Rial</option>
        <option value="£">Isle of Man Pound </option>
        <option value="₪">Israel Shekel </option>
        <option value="J$">Jamaica Dollar</option>
        <option value="¥">Japan Yen</option>
        <option value="£">Jersey Pound</option>
        <option value="лв">Kazakhstan Tenge </option>
        <option value="₩">Korea (North) Won</option>
        <option value="₩">Korea (South) Won</option>
        <option value="лв">Kyrgyzstan Som </option>
        <option value="₭">Laos Kip</option>
        <option value="£">Lebanon Pound </option>
        <option value="$">Liberia Dollar </option>
        <option value="ден">Macedonia Denar</option>
        <option value="RM">Malaysia Ringgit</option>
        <option value="₨">Mauritius Rupee </option>
        <option value="$">Mexico Peso </option>
        <option value="₮">Mongolia Tughrik </option>
        <option value="د.إ">Moroccan-dirham </option>
        <option value="MT">Mozambique Metical</option>
        <option value="$">Namibia Dollar </option>
        <option value="₨">Nepal Rupee </option>
        <option value="ƒ">Netherlands Antilles Guilder </option>
        <option value="$">New Zealand Dollar </option>
        <option value="C$">Nicaragua Cordoba </option>
        <option value="₦">Nigeria Naira </option>
        <option value="kr">Norway Krone</option>
        <option value="﷼">Oman Rial </option>
        <option value="₨">Pakistan Rupee </option>
        <option value="B/.">Panama Balboa</option>
        <option value="Gs">Paraguay Guarani</option>
        <option value="S/.">Peru Sol </option>
        <option value="₱">Philippines Peso </option>
        <option value="zł">Poland Zloty </option>
        <option value="﷼"> Qatar Riyal</option>
        <option value="lei">Romania Leu </option>
        <option value="₽">Russia Ruble </option>
        <option value="£">Saint Helena Pound</option>
        <option value="﷼">Saudi Arabia Riyal  </option>
        <option value="Дин."> Serbia Dinar</option>
        <option value="₨">Seychelles Rupee </option>
        <option value="$">Singapore Dollar </option>
        <option value="$">Solomon Islands Dollar </option>
        <option value="S">Somalia Shilling </option>
        <option value="₩">South Korean Won</option>
        <option value="R">South Africa Rand</option>
        <option value="₨">Sri Lanka Rupee</option>
        <option value="kr">Sweden Krona</option>
        <option value="CHF">Switzerland Franc</option>
        <option value="$"> Suriname Dollar</option>
        <option value="£">Syria Pound </option>
        <option value="NT$">Taiwan New Dollar</option>
        <option value="฿">Thailand Baht</option>
        <option value="TT$">Trinidad and Tobago Dollar</option>
        <option value="₺">Turkey Lira</option>
        <option value="$">Tuvalu Dollar </option>
        <option value="₴">Ukraine Hryvnia  </option>
        <option value="د.إ">UAE-Dirham </option>
        <option value="£">United Kingdom Pound </option>
        <option value="$">United States Dollar </option>
        <option value="$U">Uruguay Peso </option>
        <option value="лв">Uzbekistan Som </option>
        <option value="Bs"> Venezuela Bolívar</option>
        <option value="₫">Viet Nam Dong </option>
        <option value="﷼">Yemen Rial </option>
        <option value="Z$">Zimbabwe Dollar </option>
      </datalist>
    </>
  );
}

export default DataForm;



