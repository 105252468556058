import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {logout} from '../Auth/AuthSlice'
import {toast} from 'react-toastify'

// MD===> ConnectForm
const initialState = {
    dashboardsNames: [],
    isErrorMD: false,
    isSuccessMD: false,
    isLoadingMD: false,
    messageMD: '',
}



// create new user connect Data
export const createMultipleDashboard = createAsyncThunk('multipleDashboard/create', async (connectData, thunkAPI) => {

    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/userData/adddashboard`
        const token = await thunkAPI.getState().auth.user.token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        console.log("hello", connectData)
        const response = await axios.put(API_URL, connectData, config)

        console.log(response.data)
        return response.data

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        return thunkAPI.rejectWithValue(message)
    }
})

// get dashboards name
export const getMultipleDashboard = createAsyncThunk('multipleDashboard/get', async (_, thunkAPI) => {

    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/userData/dashboardName`
        const token = await thunkAPI.getState().auth.user.token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await axios.get(API_URL, config)
        return response.data

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        return thunkAPI.rejectWithValue(message)
    }
})


// get dashboards name
export const deleteDashboard = createAsyncThunk('deleteDashboard/delete', async (dashboardInfo, thunkAPI) => {

    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/userData/deleteDashboard`
        const token = await thunkAPI.getState().auth.user.token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await axios.delete(API_URL, {
            headers: {
                Authorization: `Bearer ${token}`
            },
            data:{
                dashboardInfo
            }
        })
        return response.data

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        return thunkAPI.rejectWithValue(message)
    }
})




export const multipleDashboardSlice = createSlice({
    name: 'multipleDashboard',
    initialState,
    reducers: {
        resetDashboard: (state) => {
            state.dashboardsNames= []
            state.messageMD = ''
            state.isErrorMD = false
            state.isSuccessMD = false
            state.isLoadingMD = false
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createMultipleDashboard.pending, (state) => {
                state.isSuccessMD = false
                state.isLoadingMD = true
            })
            .addCase(createMultipleDashboard.fulfilled, (state, action) => {
                state.isLoadingMD = false
                state.isSuccessMD = true
                state.messageMD = action.payload.message
            })
            .addCase(createMultipleDashboard.rejected, (state, action) => {
                state.isLoadingMD = false
                state.isErrorMD = true
                state.messageMD = action.payload
            })
            
            .addCase(getMultipleDashboard.pending, (state) => {
                state.isSuccessMD = false
                state.isLoadingMD = true
            })
            .addCase(getMultipleDashboard.fulfilled, (state, action) => {
                console.log("hello")
                state.isLoadingMD = false
                state.isSuccessMD = true

                if(action.payload.success===true){
                    console.log(action.payload.data.Dashboards, "ppp")
                    state.dashboardsNames = action.payload.data.Dashboards
                }
                if(action.payload.success===false){
                    state.dashboardsNames = []
                }
                state.messageMD = action.payload.message
            })
            .addCase(getMultipleDashboard.rejected, (state, action) => {
                state.isLoadingMD = false
                state.isErrorMD = true
                state.messageMD = action.payload
                toast(state.messageMD)

            })
            .addCase(deleteDashboard.pending, (state) => {
                state.isSuccessMD = false
                state.isLoadingMD = true
            })
            .addCase(deleteDashboard.fulfilled, (state, action) => {
                state.isLoadingMD = false
                state.isSuccessMD = true
                state.messageMD = action.payload.message
                console.log(action.payload,"payload")
            })
            .addCase(deleteDashboard.rejected, (state, action) => {
                state.isLoadingMD = false
                state.isErrorMD = true
                state.messageMD = action.payload
            })
    }
})


export const { resetDashboard } = multipleDashboardSlice.actions
export default multipleDashboardSlice.reducer