import "./Info.css"
import {BsArrowRight} from 'react-icons/bs'
import {Info_svg} from '../../../assets/SVG'
import {Link} from 'react-router-dom'

const Info = () => {

  return (
    <section className="info_section flex_column" >
          <div className="blob3" data-aos="slide-right"></div>
          <div className='info_hr' data-aos="slide-up"></div>


      <div className="subheading" data-aos="zoom-in" data-aos-offset="200">
      <div className="bullet" >
              {Info_svg.eye}
              <p>Why Fori</p>
        </div>
        <h3>Get actionable insights in 3 steps</h3>
        <p>Actionable insights and informed decisions can take a buisness to the next level, just plug-in the required input and you are good to go. </p>
      </div>
     
      <div className="highlight_section" data-aos="zoom-in">
      <div className="highlights">
        {Info_svg.analytics}
        <h4>Valuable business insights</h4>
        <p>By using Valuable business insights, organizations can identify opportunities for improvement, optimize their performance, and achieve their goals more efficiently and effectively.</p>
      </div>
      <div className="highlights">
        {Info_svg.algorithm}
        <h4>Powerful Algorithms</h4>
        <p>Our powerful algorithm can significantly improve the efficiency and effectiveness of a system, making it a crucial element for achieving success in your buisness decisions.</p>
      </div>
      <div className="highlights">
        {Info_svg.newsLetter}
        <h4>Data in real-time</h4>
        <p>Real-time data is essential for making timely and informed decisions, as it allows organizations to respond quickly to changing circumstances and take advantage of opportunities.</p>
      </div>
      </div>
    </section>
  )
}

export default Info