import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {LoadProfileData} from '../ProfileData/ProfileData'
import {resetDashboard} from '../multipleDashboard/multipleDasboard'
// get user from local sy=torage
const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    user:user ? user: null,
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:'',
}

export const verifyUser = createAsyncThunk('auth/verifyUser',
async(user, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/users/verifyUser`
        //register user
        const response = await axios.post(API_URL, user)
        if(response.data) {

        }
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}
)




// register user
export const register = createAsyncThunk('auth/register',
async(token, thunkAPI)=>{
    try{
        // const API_URL = 'https://analytics-backend.fori.co/api/users/register'
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/users/register`
        //register user
        const response = await axios.post(API_URL, token)
        if(response.data.success===true) {
            localStorage.setItem('user',JSON.stringify(response.data))
            localStorage.setItem('dashboard',JSON.stringify({userData:{},record:{}}))
            thunkAPI.dispatch(LoadProfileData(response.data))
        }
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
}
)

export const login = createAsyncThunk('auth/login',
async(user, thunkAPI)=>{
    try{
        // const API_URL = 'https://analytics-backend.fori.co/api/users/'
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/users/`

        //register user
        const response = await axios.post(API_URL + 'login', user)
        if(response.data.success===true) {
            localStorage.setItem('user',JSON.stringify(response.data))
            thunkAPI.dispatch(LoadProfileData(response.data))
        }
        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
}
)

export const logout  = createAsyncThunk('auth/logout', async(_,thunkAPI)=>{
    //logout user
    try{
       localStorage.removeItem('user')
       localStorage.removeItem('dashboard')
       thunkAPI.dispatch(resetDashboard())
    }catch(error){
        return thunkAPI.rejectWithValue('Logout Unsuccessful')
    }
})


export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        reset:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
        resetAll:(state)=>{
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
            state.user = null
        },


    },
    extraReducers:(builder) => {
        builder
        .addCase(verifyUser.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(verifyUser.fulfilled, (state, action)=>{
            console.log(action.payload,'verify fulfilled payload')

            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
        })
        .addCase(verifyUser.rejected, (state, action)=>{
            console.log(action.payload,'payload')
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(register.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(register.fulfilled, (state, action)=>{
            if(action.payload.success===true){
                state.user = action.payload

            }
            console.log(action.payload,'acc payload')

            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
       
        })
        .addCase(register.rejected, (state, action)=>{
            console.log(action.payload,'rejected payload')

            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null

        })
        .addCase(login.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(login.fulfilled, (state, action)=>{
            if(action.payload.success===true){
                state.user = action.payload

            }
            state.isLoading = false
            state.isSuccess = true
       
        })
        .addCase(login.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })
        .addCase(logout.fulfilled, (state)=>{
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
            state.user = null
        })
    }
})

export const {reset,resetAll} = authSlice.actions
export default authSlice.reducer