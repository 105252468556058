import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {logout} from '../Auth/AuthSlice'


// AF===> Api connect
const initialState = {
    isErrorAC: false,
    isSuccessAC: false,
    isLoadingAC: false,
    messageAC: '',
    savedAC:false,
    secretKey:"",


}



// save user data Secetkey
export const createApiData = createAsyncThunk('createApiData/create', async (data, thunkAPI) => {

    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/savedata`
        // const API_URL = 'http://localhost:5000/api/userData'
        const token = await thunkAPI.getState().auth.user.token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await axios.post(API_URL, data, config)
        return response.data

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        console.log(message)
        return thunkAPI.rejectWithValue(message)
    }
})

// create update DataAPi




export const apiConnectSlice = createSlice({
    name: 'userConnectData',
    initialState,
    reducers: {
        reset: (state) => {
            initialState}
    },
    extraReducers: (builder) => {
        builder
            .addCase(createApiData.pending, (state) => {
                state.isLoadingAC = true
                state.isSuccessAC = false
                state.isErrorAC=false
            })
            .addCase(createApiData.fulfilled, (state, action) => {
                state.isLoadingAC = false
                state.isSuccessAC = true
                state.messageAC = action.payload.message
                state.secretKey = action.payload.secretKey
                state.savedAC = action.payload.saved
            })
            .addCase(createApiData.rejected, (state, action) => {
                state.isLoadingAC = false
                state.isErrorAC = true
                state.messageAC = action.payload
                state.savedAC = false
                state.secretKey = ""
            })
    }
})


export const { reset } = apiConnectSlice.actions
export default apiConnectSlice.reducer