import React from 'react'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate,useLocation } from 'react-router-dom'
import {toast} from 'react-toastify'
import Spinner from '../../components/Spinner/Spinner'
import {TbSmartHome} from 'react-icons/tb'
import {ResetPasswordFunction, reset} from '../../features/ResetPassword/ResetPasswordSlice'
import GoBackButton from '../../components/GoBackButton/GoBackButton'


const ResertPassword = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location  = useLocation();
    const {isLoadingR, isErrorR, isSuccessR, messageR} = useSelector((store)=>store.ResetPassword)


    const token = location.pathname.substring(location.pathname.lastIndexOf('/')+1)
    const [formData, setFormData] = useState(
        {
        password:'',
        confirmPassword:'',
        })

        const {password, confirmPassword} = formData

        const onChange =(e)=>{
            setFormData((prevState)=>({...prevState, [e.target.name]:e.target.value}))
    
        }


        const onSubmit =(e)=>{
            e.preventDefault()
            if(password==='' || confirmPassword===''){
                toast.info('Please fill All fields')
            }else if(password!==confirmPassword){
                toast.info('Password did not match')
            }
            else if(password!=='' &&confirmPassword!=='' &&password===confirmPassword){
                const ResetData = {password:password, token:token}
                dispatch(ResetPasswordFunction(ResetData))
            }
        }
 

        


        useEffect(()=>{

            if(isErrorR){
                toast.error(messageR)
            }
            if(isSuccessR){
                toast.info(messageR)
                navigate('/login')
            }

            dispatch(reset())
        },[ isErrorR, messageR,isSuccessR, navigate, dispatch])

        if(isLoadingR){
            return <Spinner/>
        }

  return (
    <section className='container'>
   <div className='left'>
   <GoBackButton/>
   <h1 className='logo_icon'><Link className='Home_btn' to='/'>Fori Analytics <TbSmartHome/></Link></h1>
    <h2 className="boldHeading">Reset Password</h2>
    <h3>Resume your journey with us</h3>
    </div>

    <div className='right'>
            <div className='right_heading'>
            <h5>Hello!</h5>
            <h6>Please provide new password</h6>
            </div>
    <form onSubmit={onSubmit} className='form'>
       
            <div className="form_group">
                <input type="password"  id='password' name='password' value={password} placeholder='Enter Your password' onChange={onChange}/>
            </div>
            <div className="form_group">
                <input type="password" id='confirmPassword' name='confirmPassword' value={confirmPassword} placeholder='Confirm Your Password' onChange={onChange}/>
            </div>
            <div className="form_group">
                <button type='submit' className='btn btn_form'>Submit</button>
            </div>
    </form>
    </div>
</section>



  )
}
 
export default ResertPassword