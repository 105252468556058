import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);
const width =  window.innerWidth
let height
if(width<=900){
   height = 250
}


export function BubbleChart({data}) {

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false ,
  };
  
  return <Bubble options={options} data={data} height={height} />;
}
