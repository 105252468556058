import React,{useEffect} from 'react'
import './ConnectionTypes.css'
import { Link } from "react-router-dom";
import {MdKeyboardBackspace} from 'react-icons/md'
import {BsArrowRight} from 'react-icons/bs'
import {SetupOptions} from '../../assets/SVG'
import GoBackButton from '../../components/GoBackButton/GoBackButton';
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';

const ConnectionTypes = () => {

  const navigate= useNavigate()
  const {user} = useSelector((store)=>store.auth)

  useEffect(()=>{
      if(!user){
        toast.info("please Login first")
        navigate('/login')
      }
    },[user, navigate])

  return (
    <div className=''>
        <div className="ConnectHeading">
          <GoBackButton />
            <h3>Setup Options</h3>
            <p>Please select from these options to sign up whichever suites best to your organization</p>
        </div>
        <div className="connectionTypes">
          {SetupOptions.map((item)=>{
            return(
              <div className="option">
                <div className='learn_more'>
                  {item.optionFeature}
                </div>
                <h3>{item.optionName}</h3>
                <p>{item.description}</p>
                <div className='flex_row_gap_sp'>
                 <Link className='secondary_btn smallPadding' to={item.connectionUrl}>Let's connect</Link>
                 <Link to={item.optionLink}>Learn more<BsArrowRight/></Link>
                </div>
              </div>
            )
          })}
        </div>


    </div>
  )
}

export default ConnectionTypes