import React from 'react'
import './SignUpFormShort.css'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { verifyUser, reset } from '../../../features/Auth/AuthSlice'
import {VscEye} from 'react-icons/vsc'
import {GrFormViewHide} from 'react-icons/gr'

const SignUpFormShort = () => {

    const [passShow, setPassShow] = useState(false)
    const [CpassShow, setCPassShow] = useState(false)
    const [formData, setFormData] = useState(
        {firstName:'',
        email:'',
        password:'',
        confirmPassword:''
        })

        const {firstName, email, password, confirmPassword} = formData

        const onChange =(e)=>{
            setFormData((prevState)=>({...prevState, [e.target.name]:e.target.value}))
    
        }

        const onSubmit =(e)=>{
            e.preventDefault()
            if(email==='' || password==='' || confirmPassword==='' || firstName===''){
                toast.info('Please fill All fields')
            }else if(password!==confirmPassword){
                toast.info('Password did not match')
            }
            else if(firstName!=='' && email!=='' && password!=='' &&confirmPassword!=='' &&password===confirmPassword){
                const userData = {firstName:firstName, email:email.toLowerCase(), password:password}
                dispatch(verifyUser(userData))
            }
        }

      
        const navigate = useNavigate()
        const dispatch = useDispatch()
        

        const {user, isError, isSuccess, message} = useSelector((store)=>store.auth)

        useEffect(()=>{

            if(isError){
                toast.error(message)
            }if(isSuccess){
                toast.info(message)
            }
            dispatch(reset())
        },[user, isError,isSuccess, message, navigate, dispatch])

  return (
    <section id='contact' className='form_section'>

   
        <h3>Sign up</h3>
    <form onSubmit={onSubmit} className='form_container'>
    <div className="input_div">
                <button type='submit' className='secondary_btn'>Start Your Free trial</button>
            </div>
        <div className="input_div">
                <input type="text"  id='firstName' name='firstName' value={firstName} placeholder='Enter Your Name' onChange={onChange}/>
            </div>
            <div className="input_div">
                <input type="email"  id='email' name='email' value={email} placeholder='Enter Your Email' onChange={onChange}/>
            </div>
            <div className="input_div password_div">
                <input type={passShow===true?"text":"password"} id='password' name='password' value={password} placeholder='Enter Your password' onChange={onChange}/>
                {passShow===true?<VscEye onClick={()=>setPassShow(!passShow)}/>:<GrFormViewHide onClick={()=>setPassShow(!passShow)}/>}

            </div>
            <div className="input_div password_div">
                <input type={CpassShow===true?"text":"password"} id='confirmPassword' name='confirmPassword' value={confirmPassword} placeholder='Confirm Your Password' onChange={onChange}/>
                {CpassShow===true?<VscEye onClick={()=>setCPassShow(!CpassShow)}/>:<GrFormViewHide onClick={()=>setCPassShow(!CpassShow)}/>}

            </div>

            <hr className='form_hr' />
            <p>Already have an acount? <Link className='special_a_tag' to="/login">Login</Link></p>
    </form>
</section>


  )
}
 
export default SignUpFormShort