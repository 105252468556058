import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {logout} from '../Auth/AuthSlice'

const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    url:"",
    isError:false,
    isSuccess:false,
    isLoading:false,
    message:''
}

// register user
export const create_checkout_link = createAsyncThunk('payment/create_checkout_link',
async(data, thunkAPI)=>{
    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/payments/create_checkout_link`

        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        //payment checkout 
        const response = await axios.post(API_URL, {price_id:data.price_id},config)

        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
       console.log(message,"ss")

        return thunkAPI.rejectWithValue(message)
    }
}
)






export const create_portal_session = createAsyncThunk('payment/create_portal_session',
async(_, thunkAPI)=>{
    try{
        console.log("ss")
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/payments/create_portal_session`
        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        //payment checkout 
        const response = await axios.get(API_URL,config)
        console.log(response)

        return response.data
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
       console.log(message,"ss")

        return thunkAPI.rejectWithValue(message)
    }
}
)

// export const create_portal_session = createAsyncThunk('auth/create_portal_session',
// async(_, thunkAPI)=>{
    
//     try{
//         const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/payments/create_portal_session`
//         const token =await thunkAPI.getState().auth.user.token
//         const config = {
//             headers:{
//                 Authorization:`Bearer ${token}`
//             }
//         }
//         //payment checkout 
//         const response = await axios.post(API_URL, config)
//         return response.data
//     }catch(error){
//         const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
//         if (message === "Not Authorized" || message === "Not Authenticated"){
//             console.log("ss ")
//             thunkAPI.dispatch(logout())
//        }
//         return thunkAPI.rejectWithValue(message)
//     }
// }
// )

export const PaymentSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        reset:(state)=>{
            state.url=""
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        }

    },
    extraReducers:(builder) => {
        builder
        .addCase(create_checkout_link.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(create_checkout_link.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.url = action.payload.url
            if(action.payload.message==="success"){
                window.location.replace(state.url)

            }
        })
        .addCase(create_checkout_link.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.url = ""
        })


        .addCase(create_portal_session.pending, (state)=>{
            state.isLoading = true;
        })
        .addCase(create_portal_session.fulfilled, (state, action)=>{
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload.message
            state.url = action.payload.url
            if(action.payload.message==="success"){
                window.location.replace(state.url)

            }
        })
        .addCase(create_portal_session.rejected, (state, action)=>{
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.url = ""
        })
    }
})

export const {reset} = PaymentSlice.actions
export default PaymentSlice.reducer



