import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useState, useEffect } from 'react';



function StaticDatePickerLandscape() {
  const record  = ["2023-01-01","2023-01-03","2022-12-31","2023-12-28","2022-12-26","2022-12-20","2022-12-17","2022-12-10","2022-12-09", "2022-12-04" ,"2022-11-29","2022-11-25","2022-11-23","2022-11-21","2022-11-18","2022-11-14","2022-11-09","2022-11-06","2022-11-03","2022-11-01"]

  const DateFinder  =(selectedDate)=>{
    let  DateFound = record.map((item)=>{
      console.log(item,selectedDate,'ye')
      if(item.includes(selectedDate)){
        return item
      }else{
        console.log('ni mila')
      }
    })
    console.log(DateFound,'date Found')
    return DateFound[0]
  }
  
// Date Component

let dataArr=record.map((item)=>{
  let myDate=new Date(item)
  return `${myDate.getFullYear()}-${myDate.getMonth()}-${myDate.getDate()}`
})
const isWeekend = (date) => {

if(dataArr.includes(`${date.year()}-${date.month()}-${date.date()}`)){
  return false
}else{
  return true
}
};

  const [value, setValue] = useState(dayjs(Date.now()));



 useEffect(()=>{
  let day = value.$d.getDate();
  day = String(day)
    if(day.length===1){
      day=`0${day}`
    }

  let month = value.$d.getMonth()+1;
  month = String(month)
    if(month.length===1){
      month=`0${month}`
    }

  let year = value.$d.getFullYear();


  let selectedDate = year + "-" + month + "-" + day;
  console.log(selectedDate,'selected Date')
  let PastDate = DateFinder(selectedDate)
  console.log(PastDate,'past date')


  },[value])
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} >
      <StaticDatePicker
        disableFuture={true}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        value={value}
        shouldDisableDate={isWeekend}
        renderInput={(params) => <TextField {...params} />}
        componentsProps={{
          actionBar: {
            actions: ['today'],
          },
        }}
        // onAccept={(e)=>console.log(new Date(e))}

      />
    </LocalizationProvider>
  );
}

export default function DemoRecodedData() {
  // const dispatch = useDispatch()

  const FetchPastData =(selectedDate)=>{
    // dispatch(getRecordedData(selectedDate))
  }

  const record  = ["2023-01-01","2023-01-03","2022-12-31","2023-12-28","2022-12-26","2022-12-20","2022-12-17","2022-12-10","2022-12-09", "2022-12-04" ,"2022-11-30","2022-11-25","2022-11-23","2022-11-21","2022-11-18","2022-11-16","2022-11-09","2022-11-06","2022-11-03","2022-11-01"]
  console.log(record, 'record list')
  return(
<section className="record_section">
    <h5 className='dash_heading'>Past Records</h5>
    <p>Select a date or pick from the list</p>

   <div  className="RecordedData">
    <div>
    <StaticDatePickerLandscape style={{with:"100%"}}/>
    </div>
    <div className='recorded_data_button_container' >
      {record.map((item)=>{
        return (
          <>
          <button className='recorded_data_button' value={item} onClick={(e)=>FetchPastData(e.target.value)}>{String(new Date(item)).slice(0,16)}</button>
          </>
        )
      })}
    </div>

   </div>
</section>

  )
}