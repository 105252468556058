import React from 'react'
import {BiInfoCircle} from 'react-icons/bi'
import './InfoBox.css'


const InfoBox = ({show, setShow, graph, info}) => {
  return (
    <>
    <div className="info" onClick={(e)=>setShow({...{ graph1:false, graph2:false, graph3:false, graph4:false, graph5:false, graph6:false, graph7:false},[graph]: !(show[graph])})}><BiInfoCircle/></div>
    {show[graph] && <p className='info_para'>{info}</p>}
    </>
  )
}

export default InfoBox