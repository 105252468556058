import React,{useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation ,useNavigate} from "react-router-dom";
import { register, reset } from '../../features/Auth/AuthSlice'
import Spinner from '../../components/Spinner/Spinner'
import {toast} from 'react-toastify'
import './VerifyAcountPage.css'
import {logo_complete} from "../../assets/SVG"



const VerifyAcountPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location  = useLocation();
    const token = location.pathname.substring(location.pathname.lastIndexOf('/')+1)

    const {user, isLoading, isError, isSuccess, message} = useSelector((store)=>store.auth)
    const Verify = () =>{
        dispatch(register({token}))
    }

    useEffect(()=>{
        console.log(message,"message")

        if(isError){
            toast.error(message)
        }
        if(isSuccess){
            toast.info(message) 
            navigate('/')
        }

        dispatch(reset())
    },[user, isError, message, navigate, dispatch])

    if(isLoading){
        return <Spinner/>
    }
  return (
    <div className='verifyPageContainerWrapper'>
        <div className='verifyPageContainer'>
            <div className="verifyPageContainerHeading">
                <div className="verifyPageContainerHeadingImg">
                {logo_complete}
                </div>
            <h3>Welcome!</h3>
            </div>
        
        <div className="verifyPageContainersub">
            <div className='verifyPageContainersubImg'>
                <img src={require('../../assets/verified.png')}/>
            </div>
            <p>You are ready to start your new Fori Analytics account.
            <br/>Click the following button to activate your account</p>
            <button onClick={Verify} className='btn verifyPageContainersubBtn'>Activate your account</button>
        </div>
        </div>
       
    </div>
  )
}

export default VerifyAcountPage