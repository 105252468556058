import "./Spinner.css";
const Spinner = ({size}) => {
  return (
    <div  className={size==="small"?"spinnerContainer_small":"spinnerContainer"}>
    <div className="lds-facebook"><div></div><div></div><div></div></div>

    </div>
  )
}

export default Spinner