import { partner_assets } from '../../../assets/SVG'
import './Partners.css'
import SignUpFormShort from '../SignUpFormShort/SignUpFormShort'
import { useSelector } from 'react-redux'

const Partners = () => {
  const {user} = useSelector((store)=>store.auth)
  return (
    <section className="partners" data-aos="zoom-in">
        <div className="partners_left">
            <h3>See why the world’s best companies use it to become truly data-driven.</h3>
            <div className='partner_left_box'>
            <div className='hr'><p>Full Insights</p><hr /></div>
            <div className="poweredby">
              <div>
                <img src= {partner_assets.revenue} alt="" />
              </div>
              <div>
                <img src= {partner_assets.average_revenue} alt="" />
              </div>
              <div>
                <img src= {partner_assets.rides} alt="" />
              </div>
              <div>
                <img src= {partner_assets.users} alt="" />
              </div>
              </div>
        </div>
        </div>
     
        {!user && <div className="partners_right">
            <SignUpFormShort/>
        </div>}
    </section>
  )
}

export default Partners
