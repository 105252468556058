export const  year= {
    TotalRevenueGraph :{
        labels:[],
        datasets: [
        {
          label: 'Total revenu',
          data: [],
          borderColor: 'rgb(255, 99, 132)',backgroundColor: 'rgba(255, 99, 132, 0.5)',
        }],
      },


      AverageRevenueGraph: {
        labels:[],
        datasets: [
        {
          label: 'Total revenu',
          data: [],
          borderColor: 'rgb(255, 99, 132)',backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }
      ],
      },

    NoOfRidesGraph:{
        labels:[],
        datasets:[
            {
                label:"No pf rides",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
        ]
    },

    Co2Saved:{
        labels:[],
        datasets:[
            {
                label:"CO2 Saved",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
        ]
    },


    FuelAndTreesGraph:{
        labels:[],
        datasets:[
            {
                label:"Fuel Saved",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
            {
                label:"Trees Relaxed",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            }
        ]
    },


    PeakDays:{
        labels:["Sunday" ,"Monday","Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        datasets:[
            {
                label:"No pf rides",
                data:[0,0,0,0,0,0,0],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
        ]
    },

    LocationAndRevenue:{
        labels:[],
        datasets:[
            {
                label:"Total Revenue",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },

        ]
    },


    LocationAndUsers :
                    {
                    labels:[],
                    datasets: [
                    {
                        label: 'Total Users',
                        data: [],
                        borderColor: 'rgb(255, 99, 132)',backgroundColor: 'rgba(255, 99, 132, 0.5)'
                    }],
                    },



    LocationAndRides:
                    {
                    datasets: [
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                  ],
     },

    cardData:{ totalCost: 0, totalRides: 0, totalDistance: 0,Num_of_users: 0, totaCo2Saved:0,totalFuelSaved:0,totalTreesRelaxed:0 }


}




//Month12==============================
// ==============================
// ==================================
export const  month12= {
    TotalRevenueGraph :{
        labels:[],
        datasets: [
        {
          label: 'Total revenu',
          data: [],
          borderColor: 'rgb(255, 99, 132)',backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }],
      },


      AverageRevenueGraph: {
        labels:[],
        datasets: [
        {
          label: 'Total revenu',
          data: [],
          borderColor: 'rgb(255, 99, 132)',backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }
      ],
      },

    NoOfRidesGraph:{
        labels:[],
        datasets:[
            {
                label:"No pf rides",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
        ]
    },

    Co2Saved:{
        labels:[],
        datasets:[
            {
                label:"CO2 Saved",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
        ]
    },


    FuelAndTreesGraph:{
        labels:[],
        datasets:[
            {
                label:"Fuel Saved",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
            {
                label:"Trees Relaxed",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            }
        ]
    },


    PeakDays:{
        labels:["Sunday" ,"Monday","Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        datasets:[
            {
                label:"No pf rides",
                data:[0,0,0,0,0,0,0],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
        ]
    },

    LocationAndRevenue:{
        labels:[],
        datasets:[
            {
                label:"Total Revenue",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },

        ]
    },


    LocationAndUsers :
                    {
                    labels:[],
                    datasets: [
                    {
                        label: 'Total Users',
                        data: [],
                        borderColor: 'rgb(255, 99, 132)',backgroundColor: 'rgba(255, 99, 132, 0.5)'
                    }],
                    },


                    
    LocationAndRides:
                    {
                    datasets: [
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                  ],
     },

    cardData:{ totalCost: 0, totalRides: 0, totalDistance: 0,Num_of_users: 0, totaCo2Saved:0,totalFuelSaved:0,totalTreesRelaxed:0 }


}



//days30==============================
// ==============================
// ==================================

export const  days30= {
    TotalRevenueGraph :{
        labels:[],
        datasets: [
        {
          label: 'Total revenu',
          data: [],
          borderColor: 'rgb(255, 99, 132)',backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }],
      },


      AverageRevenueGraph: {
        labels:[],
        datasets: [
        {
          label: 'Total revenu',
          data: [],
          borderColor: 'rgb(255, 99, 132)',backgroundColor: 'rgba(255, 99, 132, 0.5)'
        }
      ],
      },

    NoOfRidesGraph:{
        labels:[],
        datasets:[
            {
                label:"No pf rides",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
        ]
    },

    Co2Saved:{
        labels:[],
        datasets:[
            {
                label:"CO2 Saved",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
        ]
    },


    FuelAndTreesGraph:{
        labels:[],
        datasets:[
            {
                label:"Fuel Saved",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
            {
                label:"Trees Relaxed",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            }
        ]
    },


    PeakDays:{
        labels:["Sunday" ,"Monday","Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        datasets:[
            {
                label:"No pf rides",
                data:[0,0,0,0,0,0,0],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },
        ]
    },

    LocationAndRevenue:{
        labels:[],
        datasets:[
            {
                label:"Total Revenue",
                data:[],
                borderColor:'rgba(133, 221, 155, 1)',
                backgroundColor:'rgba(133, 221, 155, 0.31)',
            },

        ]
    },


    LocationAndUsers :
                    {
                    labels:[],
                    datasets: [
                    {
                        label: 'Total Users',
                        data: [],
                        borderColor: 'rgb(255, 99, 132)',backgroundColor: 'rgba(255, 99, 132, 0.5)'
                    }],
                    },


                    
    LocationAndRides:
                    {
                    datasets: [
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                    {
                      label: '',
                      data: [],
                      borderColor: 'rgb(53, 162, 235)',backgroundColor: 'rgba(53, 162, 235, 0.5)'
                    },
                  ],
     },

    cardData:{ totalCost: 0, totalRides: 0, totalDistance: 0,Num_of_users: 0, totaCo2Saved:0,totalFuelSaved:0,totalTreesRelaxed:0 }


}
