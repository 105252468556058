import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import "./RecordedData.css";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRecordedData, getAllRecords } from "../../features/RecordSlice/RecordSlice";
import RecordedDataVisualization from "../../components/recordedDataVisualization/RecordedDataVisualization";
import Spinner from "../../components/Spinner/Spinner";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

function StaticDatePickerLandscape() {
  const location = useLocation()
  const  id=  location.pathname.substring(location.pathname.lastIndexOf('/')+1)
  const dispatch = useDispatch();
  const { record} = useSelector((store) => store.RecordedData);
  const navigate= useNavigate()
  const {user} = useSelector((store)=>store.auth)

  useEffect(()=>{
    if(!user){
      toast.info("please Login first")
      navigate('/login')
    }
  },[user, navigate])
  useEffect(()=>{
    if(user){
      dispatch(getAllRecords(id))
    }
  },[])
  const DateFinder = (selectedDate) => {
    let date=undefined
    let DateFound = record.map((item) => {
      if (item.includes(selectedDate)) {
      // console.log(item, selectedDate, "ye");
        date=item;
      }
    });
    return date;
  };

  // Date Component

  let dataArr = record.map((item) => {
    let myDate = new Date(item);
    return `${myDate.getFullYear()}-${myDate.getMonth()}-${myDate.getDate()}`;
  });
  const isWeekend = (date) => {
    if (dataArr.includes(`${date.year()}-${date.month()}-${date.date()}`)) {
      return false;
    } else {
      return true;
    }
  };

  const [value, setValue] = useState(dayjs(Date.now()));

  useEffect(() => {
    let day = value.$d.getDate();
    day = String(day);
    if (day.length === 1) {
      day = `0${day}`;
    }

    let month = value.$d.getMonth() + 1;
    month = String(month);
    if (month.length === 1) {
      month = `0${month}`;
    }

    let year = value.$d.getFullYear();

    let selectedDate = year + "-" + month + "-" + day;
    console.log(selectedDate, "selected Date");
    let PastDate = DateFinder(selectedDate);
    console.log(PastDate, "past date");

    if (typeof PastDate === "string") {
      dispatch(getRecordedData({requiredDate:PastDate,id}));
    } else {
      console.log("type of pastdate is not string");
    }
  }, [value]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        disableFuture={true}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        value={value}
        shouldDisableDate={isWeekend}
        renderInput={(params) => <TextField {...params} />}
        componentsProps={{
          actionBar: {
            actions: ["today"],
          },
        }}
        // onAccept={(e)=>console.log(new Date(e))}
      />
    </LocalizationProvider>
  );
}

export default function RecodedData() {
  const location = useLocation()
  const  id=  location.pathname.substring(location.pathname.lastIndexOf('/')+1)
  const dispatch = useDispatch();
  const navigate= useNavigate()
  const {user} = useSelector((store)=>store.auth)

  useEffect(()=>{
      if(!user){
        toast.info("please Login first")
        navigate('/login')
      }
    },[user, navigate])

  const FetchPastData = (requiredDate) => {
      dispatch(getRecordedData({requiredDate, id}));
  };


  const { record, userDataR, isSuccessR, isErrorR, isLoadingR, messageR} = useSelector(
    (store) => store.RecordedData
  );
useEffect(()=>{
  if(isErrorR && !isLoadingR){
    toast.error(messageR)
    console.log(messageR,"errorr")
  }
  if(isSuccessR&& !isLoadingR){
    toast.info(messageR)

  }
},[isLoadingR])

  return (
    <>
      <section className="record_section">
        <h5 className="dash_heading">Past Records</h5>
        <p>Select a date or pick from the list</p>

        <div className="RecordedData">
          <div>
            <StaticDatePickerLandscape style={{ with: "100%" }} />
          </div>
          {isLoadingR===true ? <div className="recorded_data_button_container">
            <Spinner size="small"/>
          </div>:
                    <div className="recorded_data_button_container">
                    {record.length!==0?record.map((item, index) => {
                      return (
                          <button key={index}
                            className="recorded_data_button"
                            value={item}
                            onClick={(e) => FetchPastData(e.target.value)}
                          >
                            {String(new Date(item)).slice(0, 16)}
                          </button>
                      );
                    }):<p>No record found</p>}
                  </div>}
        </div>
        {isLoadingR ? (
        <p>Loading...</p>
      ) : (
        <section className="previous_record">
          <hr />
          {isErrorR ? <h2 className="dash_heading">Some Thing went wrong</h2> : null}
          {isSuccessR ? (
            <RecordedDataVisualization userData={userDataR} />
          ) : null}
        </section>
      )}
      </section>
      
    </>
  );
}
