import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { month12, year, days30 } from "./GraphData";
const user = JSON.parse(localStorage.getItem('user'))
const dashboard = JSON.parse(localStorage.getItem('dashboard'))
import {logout} from '../Auth/AuthSlice'

const initialState = {
    userData: user && dashboard && dashboard.userData ? dashboard.userData :
        {
            year: year,
            month12: month12,
            days30: days30,
            DashboardName: "My Dashboard",
            dashboardId: "",
            currency: "$",
        },
    report: null,
    isErrorUS: false,
    isSuccessUS: false,
    isLoadingUS: false,
    messageUS: '',
}

// Get prev User userData
export const requiredDashboard = createAsyncThunk('prevUserData/getData', async (dashboard, thunkAPI) => {
    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/userData/requiredDashboard`
        const token = thunkAPI.getState().auth.user.token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await axios.post(API_URL, dashboard , config)
        if (response.data.message === "Success") {
            localStorage.setItem('dashboard', JSON.stringify(response.data))
        }
        return response.data

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        return thunkAPI.rejectWithValue(message)
    }
})



// Get User userData
export const getuserData = createAsyncThunk('userData/getData', async (dashboardId, thunkAPI) => {
    try {
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/userData/mydashboard`
        const token = thunkAPI.getState().auth.user.token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await axios.post(API_URL, { dashboardId }, config)
        if (response.data.message === "Data Successfully Refreshed") {
            localStorage.setItem('dashboard', JSON.stringify(response.data))
        }
        return response.data

    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        return thunkAPI.rejectWithValue(message)
    }
})



export const userDatalice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        reset: (state) => state = initialState,
        resetMessge: (state) => {
            state.messageUS = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(requiredDashboard.pending, (state) => {
                state.isLoadingUS = true
            })
            .addCase(requiredDashboard.fulfilled, (state, action) => {
                if (action.payload.success === true) {
                    state.isSuccessUS = true
                    state.userData = action.payload.userData
                    state.isLoadingUS = false
                    state.messageUS = action.payload.message
                    state.report = action.payload.report
                } else {
                    state.isSuccessUS = false
                    state.isLoadingUS = false
                    state.messageUS = action.payload.message
                }
            })
            .addCase(requiredDashboard.rejected, (state, action) => {
                console.log(action.payload, 'reject hoi')
                state.isLoadingUS = false
                state.isErrorUS = true
                state.messageUS = action.payload.message

            })
            .addCase(getuserData.pending, (state) => {
                state.isLoadingUS = true
            })
            .addCase(getuserData.fulfilled, (state, action) => {
                if (action.payload.success === true) {
                    state.isSuccessUS = true
                    state.userData = action.payload.userData
                    state.isLoadingUS = false
                    state.messageUS = action.payload.message
                    state.report = action.payload.report
                } else {
                    state.isSuccessUS = false
                    state.isLoadingUS = false
                    state.messageUS = action.payload.message
                }
            })
            .addCase(getuserData.rejected, (state, action) => {
                state.isLoadingUS = false
                state.isErrorUS = true
                state.messageUS = action.payload.message

            })

    }
})


export const { reset, resetMessge, LoadLocalStorageData } = userDatalice.actions
export default userDatalice.reducer