import React, { useState } from 'react'
import {work_process} from '../../../assets/SVG'
import './WorkProcess.css'
import AOS from 'aos';
import { Link } from 'react-router-dom';


const WorkProcess = () => {
    AOS.init({offset: 400, 
        delay: 10});
    const [instruction, setInstruction] = useState(work_process[0])
    const [active, setActive] = useState(0)
    const changeInstruction =(index)=>{
        setActive(index)
        setInstruction(work_process[index])
    }


  return (

    <section className='work' data-aos="slide-right" data-aos-offset="200">
        <h3>Work Process</h3>

        <div className="workProcess">
        <div className="left_work" >
        {work_process.map((item,index)=>{
                return(
                    <div className='work_button' key={index}>
                    <div className={active===index?"work_hr_active":"work_hr_deactive"}></div>
                    <button className={active===index?"active_button":"work_button_deactive"} key={index} onClick={()=>changeInstruction(index)}>{item.name}</button>
                    </div>
                )
            })
        }
        </div>

        <div className="center_work" >
            <p>{instruction.description}</p>
            {/* <Link to='/instruction' className='secondary_btn'>Learn More</Link> */}
        </div>
        <div className="right_work" >
            {instruction.svg}
        </div>
        </div>
    </section>
  )
}

export default WorkProcess