import React from 'react'
import { useState,useEffect } from 'react'
import './Profile.css'
import { useSelector,useDispatch } from 'react-redux'
import {ProfileDataUpdation} from '../../features/ProfileData/ProfileData'
import {toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'

const Profile = () => {
  const dispatch = useDispatch()
  const {ProfileData} = useSelector((Store)=>Store.ProfileData)
  const [profile, setProfile] = useState({firstName:'', surName:'', jobTitle:'', companyName:'',companyType:'', email:'', contactNum:'', country:'',city:''})
  const navigate= useNavigate()
  const {user} = useSelector((store)=>store.auth)

  useEffect(()=>{
      if(!user){
        toast.info("please Login first")
        navigate('/login')
      }
    },[user, navigate])


  const UpdateData =(profile)=>{
    const {firstName, surName, jobTitle, companyName,companyType, email, contactNum, country,city} = profile
    if(firstName==='' &&  surName==='' &&  jobTitle==='' &&  companyName==='' && companyType==='' &&  email==='' &&  contactNum==='' &&  country==='' && city===''){
      toast.info("Please Update atleast one property")
    }else{
      dispatch(ProfileDataUpdation(profile))
    }
  }
  
  return (
      <div className='Right_container'>
        <div className="profile_container">
            <h5 className='dash_heading'>Edit Profile</h5>

          <div className="profile_section">

            <div className="profile_left">

              <div className="name_input_profile">
              <div className="profile_input">
              <h4 className="profile_heading">First Name</h4>
              <input type="text" id='firstName' name='firstName' value={profile.firstName} placeholder={ProfileData.firstName} onChange={(e)=>setProfile({...profile,[e.target.id]:e.target.value})}/>
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Surname</h4>
              <input type="text" id='surName' name='surName' value={profile.surName} placeholder={ProfileData.surName} onChange={(e)=>setProfile({...profile,[e.target.id]:e.target.value})}/>
              </div>
              </div>


              <div className="profile_input">
              <h4 className="profile_heading">Job Title</h4>
              <input type="text" id='jobTitle' name='jobTitle' value={profile.jobTitle} placeholder={ProfileData.jobTitle} onChange={(e)=>setProfile({...profile,[e.target.id]:e.target.value})}/>
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Company Name</h4>
              <input type="text" id='companyName' name='companyName' value={profile.companyName} placeholder={ProfileData.companyName} onChange={(e)=>setProfile({...profile,[e.target.id]:e.target.value})}/>
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Company Type</h4>
              <input type="text" id='companyType' name='companyType' value={profile.companyType} placeholder={ProfileData.companyType} onChange={(e)=>setProfile({...profile,[e.target.id]:e.target.value})}/>
              </div>

            </div>



            <div className="profile_left">

              <div className="profile_input">
              <h4 className="profile_heading">Buisness Email</h4>
              <input type="email" id='email' name='email' value={ProfileData.email} placeholder={ProfileData.email} readOnly />
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Phone Number</h4>
              <input type="text" id='contactNum' name='contactNum' value={profile.contactNum} placeholder={ProfileData.contactNum} onChange={(e)=>setProfile({...profile,[e.target.id]:e.target.value})}/>
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Country</h4>
              <input type="text" id='country' name='country' value={profile.country} placeholder={ProfileData.country} onChange={(e)=>setProfile({...profile,[e.target.id]:e.target.value})}/>
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">City</h4>
              <input type="text" id='city' name='city' value={profile.city} placeholder={ProfileData.city} onChange={(e)=>setProfile({...profile,[e.target.id]:e.target.value})}/>
              </div>

            </div>

          </div>
          <button className='sidebar_btn_active profile_btn' onClick={()=>UpdateData(profile)}>Save</button>

        </div>

        
      </div>
        
  )
}

export default Profile