import Header from '../../components/Header/Header';
import About from '../../components/homeComponents/about/About'
import Info from "../../components/homeComponents/info/Info"
import Pricing from "../../components/homeComponents/pricing/Pricing"
import Banner from '../../components/homeComponents/Banner/Banner'
import Partners from '../../components/homeComponents/Partners/Partners'
import Footer from '../../components/homeComponents/Footer/Footer'
import './Home.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import WorkProcess from '../../components/homeComponents/WorkProcess/WorkProcess';
import HomeSideBar from '../../components/homeComponents/HomeSideBar/HomeSideBar';



const Home = () => {
  AOS.init({offset: 200, 
    delay: 10});
  
  return (
    <section className='home'>
      <HomeSideBar/>
      <Header/>
      <Banner/>
      <Info/>
      <WorkProcess/>
      <About/>
      <Pricing/>
      <Partners/>
      <Footer/>
    </section>
  )
}

export default Home
