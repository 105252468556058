import './Header.css'
import {Link, useNavigate} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, resetAll } from '../../features/Auth/AuthSlice'
import {TbDoorExit} from 'react-icons/tb'
import {FaUserPlus} from 'react-icons/fa'
import {TbDoorEnter} from 'react-icons/tb'
import { useEffect, useState } from 'react'
import {logo_complete} from "../../assets/SVG"
const Header = () => {
    
    const [width, setWidth] = useState(window.innerWidth)
    const {user} = useSelector((store)=>store.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onLogout=()=>{
        dispatch(logout())
        .then(dispatch(resetAll()))
        .then(navigate('/login'))
    }

    useEffect(()=>{
        setWidth(window.innerWidth)
    })
  return (
    <header className='header'>

        <div className="header_left">
        {logo_complete}
        </div>
       
        <ul className='header_right'>
            
        <Link className='btn smallPadding hide' to='/dashboards'>Dashboard</Link>
        <Link className='secondary_btn smallPadding hide' to='/viewDemo'>Demo</Link>
        <Link to='/contactUs'  className='secondary_btn smallPadding borderless'>Contact</Link>

        {user && user.userType==="normal_user" && <Link to='/subscriptions'  className='fourthLink secondary_btn smallPadding borderless hide'>Subscriptions</Link>}



        {user?
         <> 

            <button className='secondary_btn smallPadding hideBtn borderless' onClick={onLogout}><TbDoorExit className='icon_buttons'/><p className='text_button'>Logout</p></button>   
            <div className="avatar flex_row">{user.firstName.slice(0,1)}</div>
            <h6 className='header_userName'>{user.firstName}</h6>
         </>
            :
        <>
            <Link  className='secondary_btn smallPadding borderless' to='/login'><TbDoorEnter  className='icon_buttons'/><p className='text_button'>Login</p></Link>
            <Link className='secondary_btn smallPadding borderless' to='/register'><FaUserPlus  className='icon_buttons'/><p className='text_button'>Register</p></Link>
 
           
        </>
        }
        </ul>

    </header>
  )
}

export default Header