import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardBackspace } from "react-icons/md";

const GoBackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
          <MdKeyboardBackspace className="back_button" onClick={goBack} />
  );
};

export default GoBackButton;