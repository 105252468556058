import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    isErrorF:false,
    messageF:'',
    isLoadingF:false,
    isSuccessF:false
}




// Get User userData
export const ForgotPasswordFunction = createAsyncThunk('ForgotPassword/sendEmail', async(email, thunkAPI)=>{

    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/users/forgot-password`
        // const API_URL = 'http://localhost:5000/api/users/forgot-password'
        const response = await axios.put(API_URL, {email})
        console.log(response.data)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        return thunkAPI.rejectWithValue(message)
    }
})





export const ForgotPasswordSlice = createSlice({
    name:'ForgotPassword',
    initialState,
    reducers:{
        reset: (state)=> initialState,
    },
    extraReducers:(builder)=>{
        builder
        .addCase(ForgotPasswordFunction.pending, (state, action)=>{
            state. isLoadingF = true
        })
        .addCase(ForgotPasswordFunction.fulfilled, (state, action)=>{
            state.messageF = action.payload.message
            state. isLoadingF = false
            state. isSuccessF = true
        })
        .addCase(ForgotPasswordFunction.rejected, (state, action)=>{
            state.isErrorF = true
            state.messageF = action.payload
        })

    }
})


export const {reset} = ForgotPasswordSlice.actions
export default ForgotPasswordSlice.reducer