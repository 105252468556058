import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const user = JSON.parse(localStorage.getItem('user'))
import {logout} from '../Auth/AuthSlice'



const initialState = {
    ProfileData:{firstName:(user && user.firstName)?user.firstName:'', surName:(user && user.surName)?user.surName:'', jobTitle:(user && user.jobTitle)?user.jobTitle:'', companyName:(user && user.companyName)?user.companyName:'',companyType:(user && user.companyType)?user.companyType:'', email:(user && user.email)?user.email:'', contactNum:(user && user.contactNum)?user.contactNum:0, country:(user && user.country)?user.country:'',city:(user && user.city)?user.city:''},
    isErrorUS:false,
    messageUS:'',
    isLoadingUS:false,
}




// Get User userData
export const ProfileDataUpdation = createAsyncThunk('ProfileData/UpdateData', async(ProfileData, thunkAPI)=>{

    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/users/profileUpdate`
        var Data_to_send ={}
        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        // console.log(ProfileData)
        for(let key in ProfileData){
            if(ProfileData[key]!==''){
                Data_to_send[key] = ProfileData[key]
            }
        }
        console.log(Data_to_send)
        const response = await axios.patch(API_URL, Data_to_send,config)
        return response.data
 
    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        return thunkAPI.rejectWithValue(message)
    }
})





export const ProfileData = createSlice({
    name:'ProfileDataUpdation',
    initialState,
    reducers:{
        reset: (state)=> state = initialState,
        LoadProfileData:(state, action)=>{
            state.ProfileData = action.payload
        }
    },
    extraReducers:(builder)=>{
        builder
        .addCase(ProfileDataUpdation.pending, (state, action)=>{
            state.isLoadingUS = true
        })
        .addCase(ProfileDataUpdation.fulfilled, (state, action)=>{
            state.isLoadingUS = false
            state.messageUS = action.payload.message
            state.ProfileData = action.payload.updatedUser
        })
        .addCase(ProfileDataUpdation.rejected, (state, action)=>{
            state.isErrorUS=true
            state.messageUS = action.payload.message
        })

    }
})


export const {reset, LoadProfileData} = ProfileData.actions
export default ProfileData.reducer