import React from 'react'
import {DummyProfileData} from '../../assets/DummyData'
import {toast} from 'react-toastify'

const DemoProfile = () => {
  const ProfileData = DummyProfileData

  return (
      <div className='Right_container'>
        <div className="profile_container">
            <h5 className='dash_heading'>Edit Profile</h5>

          <div className="profile_section">

            <div className="profile_left">

              <div className="name_input_profile">
              <div className="profile_input">
              <h4 className="profile_heading">First Name</h4>
              <input type="text" id='firstName' name='firstName' placeholder={ProfileData.firstName} />
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Surname</h4>
              <input type="text" id='surName' name='surName' placeholder={ProfileData.surName} />
              </div>
              </div>


              <div className="profile_input">
              <h4 className="profile_heading">Job Title</h4>
              <input type="text" id='jobTitle' name='jobTitle'  placeholder={ProfileData.jobTitle} />
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Company Name</h4>
              <input type="text" id='companyName' name='companyName' placeholder={ProfileData.companyName} />
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Company Type</h4>
              <input type="text" id='companyType' name='companyType'  placeholder={ProfileData.companyType} />
              </div>

            </div>



            <div className="profile_left">

              <div className="profile_input">
              <h4 className="profile_heading">Buisness Email</h4>
              <input type="email" id='email' name='email' placeholder={ProfileData.email} />
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Phone Number</h4>
              <input type="text" id='contactNum' name='contactNum' placeholder={ProfileData.contactNum} />
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">Country</h4>
              <input type="text" id='country' name='country' placeholder={ProfileData.country} />
              </div>

              <div className="profile_input">
              <h4 className="profile_heading">City</h4>
              <input type="text" id='city' name='city'  placeholder={ProfileData.city} />
              </div>

            </div>

          </div>
              <button className='sidebar_btn_active profile_btn' onClick={()=>toast.info('Please Connect Your Dashboard')}>Save</button>

        </div>

        
      </div>
        
  )
}

export default DemoProfile