import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {logout} from '../Auth/AuthSlice'


// CF===> ConnectForm
const initialState = {
    isErrorCF:false,
    isSuccessCF:false,
    isLoadingCF:false,
    messageCF:'',

}



// create new user connect Data
export const createConnectData = createAsyncThunk('userConnect/create', async(connectData, thunkAPI)=>{

    try{
        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/userData/createUserFormData`
        // const API_URL = 'http://localhost:5000/api/userData'
        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        console.log("hello",connectData)
        const response = await axios.post(API_URL, connectData, config)
        return response.data

    }catch(error){
        console.log(error,"error")
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()

        if (message === "Not Authorized" || message === "Not Authenticated"){
             thunkAPI.dispatch(logout())
        }
        return thunkAPI.rejectWithValue(message)
    }
})

// create new user connect Data
export const updateConnectData = createAsyncThunk('updateConnect/create', async(data, thunkAPI)=>{

    try{
        const {data:updatedData, id:dashboardId} = data

        const API_URL = `${process.env.REACT_APP_SERVER_URL}/api/userData/updateFormData`
        var newData = {}
        const token =await thunkAPI.getState().auth.user.token
        const config = {
            headers:{
                Authorization:`Bearer ${token}`
            }
        }
        for(let key in updatedData){
            if(updatedData[key]!==''){
                newData[key] = updatedData[key]
            }
        }
        
        const response = await axios.put(API_URL, {newData,dashboardId}, config)
        return response.data

    }catch(error){
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        if (message === "Not Authorized" || message === "Not Authenticated"){
            thunkAPI.dispatch(logout())
       }
        return thunkAPI.rejectWithValue(message)
    }
})




export const connectDataSlice = createSlice({
    name:'userConnectData',
    initialState,
    reducers:{
        reset: (state)=> initialState
    },
    extraReducers:(builder)=>{
        builder
        .addCase(createConnectData.pending, (state)=>{
            state.isLoadingCF = true
        })
        .addCase(createConnectData.fulfilled, (state, action)=>{
            state.isLoadingCF = false
            state.isSuccessCF = true
            state.messageCF=action.payload.message
        })
        .addCase(createConnectData.rejected, (state, action)=>{
            state.isLoadingCF = false
            state.isErrorCF=true
            console.log(action.payload)
            state.messageCF = action.payload
        })
        .addCase(updateConnectData.pending, (state)=>{
            state.isLoadingCF = true
        })
        .addCase(updateConnectData.fulfilled, (state, action)=>{
            state.isLoadingCF = false
            state.isSuccessCF = true
            state.messageCF=action.payload.message
        })
        .addCase(updateConnectData.rejected, (state, action)=>{
            state.isLoadingCF = false
            state.isErrorCF=true
            console.log(action.payload)
            state.messageCF = action.payload
        })
    }
})


export const {reset} = connectDataSlice.actions
export default connectDataSlice.reducer