export const setObject = (key, obj) =>{
    localStorage.setItem(key, JSON.stringify(obj));
}

export const getObject = (key) =>{
    return JSON.parse(localStorage.getItem(key));
}


export const updateItem = (key, property, value)=>{
    var obj = getObject(key);
    obj[property] = value;    
    setObject(key, obj);
}


export const generateSecretKey=(apiData,setApiData)=> {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let secretKey = "";

    for (let i = 0; i < 25; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      secretKey += characters.charAt(randomIndex);
    }

    setApiData({...apiData,secretKey:secretKey});
  }

    export const copyToClipBoard = async (e, copyMe,copySuccess,setCopySuccess) => {
    e.preventDefault();
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess({ ...copySuccess, [e.target.id]: "Copied!" });
    } catch (err) {
      setCopySuccess({ ...copySuccess, [e.target.id]: "Failed to copy!" });
    }
  };


