import React from 'react'
import './Card.css'

const Card = (props) => {
    const {heading, amount, currency, color}  =props
    
    function getSuffixedNumber(num) {
      var SUFFIXES = ['', 'K', 'M', 'B', 'T', 'Q'];
      var power = Math.floor(Math.log10(num));
      var index = Math.floor(power / 3);
  
      // Check if the number is less than 1000 (index < 0)
      if (index < 0) {
          return num.toFixed(2); // Round to 2 decimal places and return as a string
      }
  
      num = Math.round(num / Math.pow(10, (index * 3))); // first 3 digits of the number
      return num + (SUFFIXES[index] || ''); // Use index instead of index - 1 to prevent out of bounds access
  }
  return (
    <div className='card' style={{backgroundColor:color.CardBackground, color:color.CardTextColor}}>
        <h4>{heading}</h4>
        <h3>{getSuffixedNumber(amount)} {currency}</h3>
    </div>
  )
}

export default Card