import React, {useState} from 'react'
import {TbMessageReport} from 'react-icons/tb'
import './DashboardHeader.css'
import { useSelector } from 'react-redux'

const DashboardHeader = () => {

  const  clickHandle = ()=>{
    setShow(!show)
    setTimeout(()=>{
      setShow(false)
    },6000)
  }

 

  const {user} = useSelector((store)=>store.auth)
  const {report} = useSelector((store)=>store.userData)
  const [show, setShow] = useState(false)

  return (
    <>
    <section className='DashboardHeader'>
        <div className='notification_icon' onClick={clickHandle}>
          {report && <div className='dot'>!</div>}
        <TbMessageReport/>
        </div>
        <div className="avatar flex_row">{!user?'G':user.firstName.slice(0,1)}</div>
        <h6 className='userName'>{!user?'Guest':user.firstName}</h6>
    </section>
    <p className={show?'notification_para noti_animation':"notification_para"}>{(show && report) && `"${report}" items removed due to voilation of required pattern`}</p>
    </>
  )
}

export default DashboardHeader