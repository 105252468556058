import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Home from './pages/Home/Home';
import Connect from './pages/Connect/Connect';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import Profile from './pages/Profile/Profile';
import AnalyticalDashboard from './pages/AnalyticalDashboard/AnalyticalDashboard'
import ImpactAnalysis from './pages/ImapctAnalysis/ImpactAnalysis';
import RecordedData from './pages/RecordedData/RecordedData';
import DemoDashboardPage from './pages/DemoPages/DemoDashboardPage'
import DemoAnalyticalDashboard  from './pages/DemoPages/DemoAnalyticalDashboard'
import DemoProfile  from './pages/DemoPages/DemoProfilePage'
import DemoImpactAnalysis  from './pages/DemoPages/DemoImpactAnalysis'
import DemoRecorderData  from './pages/DemoPages/DemoRecorderData'
import ContactUs from './pages/ContactUs/ContactUs';
import VerifyAcountPage from './pages/VerifyAcountPage/VerifyAcountPage';
import CustomizeDashboard from './pages/CustomizeDashboard/CustomizeDashboard';
import DemoCustomizeDashboard from './pages/DemoPages/DemoCustomizeDashboard';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import DashboardName from './pages/dashboardsName/DashboardName';
import AddDashboard from "./pages/dashboardsName/AddDashboard"
import ConnectionTypes from './pages/ConnectionTypes/ConnectionTypes';
import ConnectionTypeInfo from './pages/ConnectionTypeInfo/ConnectionTypeInfo';
import APIconnectivity from './pages/APIconnectivity/APIconnectivity';
import SuccessPage from './pages/SuccessPage/SuccessPage'
import PricingPage from './pages/PricingPage/PricingPage'

function App() {
  return (
    <>
    <Router>
      <Routes>
      <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path="/connect" element={<ConnectionTypes/>}/>
        <Route path="/connectionType/:type" element={<ConnectionTypeInfo/>}/>
        <Route path="/connectDashboard" element={<Connect/>}/>
        <Route path="/connectApi/:connectionType" element={<APIconnectivity/>}/>
        <Route path="/profile" element={<Profile/>}/>
        <Route path="/contactUS" element={<ContactUs/>}/>
        <Route path="/authentication-activate/:token" element={<VerifyAcountPage/>}/>
        <Route path="/forgot-Password" element={<ForgotPassword/>}/>
        <Route path="/reset-Password/:token" element={<ResetPassword/>}/>
        <Route path="/dashboards" element={<DashboardName/>}/>
        <Route path="/adddashboard" element={<AddDashboard/>}/>
        <Route path="/payment-Successful" element={<SuccessPage/>}/>
        <Route path="/payment-Cancelled" element={<SuccessPage/>}/>
        <Route path="/subscriptions" element={<PricingPage/>}/>

            {/* Client Dashboard Routes */}
        <Route path='/analysis/' element={<DashboardPage />} >
          <Route index element={<Navigate to='mydashboard' replace />} />
          <Route path="mydashboard/:id" element={<AnalyticalDashboard />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="imapctAnalysis/:id" element={<ImpactAnalysis />} />
          <Route path="pastDetails/:id" element={<RecordedData />} />
          <Route path="customiseDashboard/:id" element={<CustomizeDashboard />} />
        </Route>
        

        {/* Demo Dashboard Routes */}
        <Route path='/viewDemo' element={<DemoDashboardPage/>} >
          <Route index element={<Navigate to='example' replace />} />
          <Route path="example" element={<DemoAnalyticalDashboard />} />
          <Route path="profile" element={<DemoProfile />} />
          <Route path="imapctAnalysis" element={<DemoImpactAnalysis />} />
          <Route path="pastDetails" element={<DemoRecorderData />} />
          <Route path="customiseDashboard" element={<DemoCustomizeDashboard />} />
        </Route>


    


      </Routes>
    </Router>
    <ToastContainer position="top-right"
autoClose={1800}
hideProgressBar={false}
newestOnTop
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"/>
    </>
  );
}

export default App;
