import React from 'react'
import { useState, useEffect } from 'react'
import {VscEye} from 'react-icons/vsc'
import {GrFormViewHide} from 'react-icons/gr'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'
import { verifyUser, reset } from '../../features/Auth/AuthSlice'
import Spinner from '../../components/Spinner/Spinner'
import AOS from 'aos';
import GoBackButton from '../../components/GoBackButton/GoBackButton'



const Register = () => {
    AOS.init({offset: 400, 
        delay: 10});
    const location = useLocation()
   const awsToken = location.search.substring(1)
   console.log(awsToken)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [passShow, setPassShow] = useState(false)
    const [CpassShow, setCPassShow] = useState(false)
    
    const [formData, setFormData] = useState(
        {firstName:'',
        email:'',
        password:'',
        confirmPassword:'',
        })

        const {firstName, email, password, confirmPassword} = formData

        const onChange =(e)=>{
            setFormData((prevState)=>({...prevState, [e.target.name]:e.target.value}))
    
        }


        const onSubmit =(e)=>{
            e.preventDefault()
            if(email==='' || password==='' || confirmPassword==='' || firstName===''){
                toast.info('Please fill All fields')
            }else if(password!==confirmPassword){
                toast.info('Password did not match')
            }
            else if(firstName!=='' && email!=='' && password!=='' &&confirmPassword!=='' &&password===confirmPassword){
                const userData = {firstName:firstName, email:email.toLowerCase(), password:password, awsToken:awsToken, userType:awsToken!==''?"aws_user":"normal_user"}
                dispatch(verifyUser(userData))
            }
        }
 

        

        const {isLoading, isError, isSuccess, message} = useSelector((store)=>store.auth)

        useEffect(()=>{
            if(isError){
                toast.error(message)
            }
            if(isSuccess){
                toast.info(message)
                navigate('/')
            }

            dispatch(reset())
        },[ isError, message, navigate, dispatch])

        if(isLoading){
            return <Spinner/>
        }

  return (
    <section className='container' data-aos="slide-right">
   <div className='left'>
   <GoBackButton/>
            <h3>Sign Up to Dashboard</h3>
            <p>Monitor your business on realtime dashboard</p>
    </div>

    <div className='right'>
            <div  className='right_heading'>
            <h5>Hello!</h5>
            <h6>Nice to see you</h6>
            </div>
    <form onSubmit={onSubmit} className='form'>
       
        <div className="form_group">
                <input type="text"  id='firstName' name='firstName' value={firstName} placeholder='Enter Your Name' onChange={onChange}/>
            </div>
            <div className="form_group">
                <input type="email"  id='email' name='email' value={email} placeholder='Enter Your Email' onChange={onChange}/>
            </div>
            <div className="form_group password_div">
                <input type={passShow===true?"text":"password"}  id='password' name='password' value={password} placeholder='Enter Your password' onChange={onChange}/>
                {passShow===true?<VscEye onClick={()=>setPassShow(!passShow)}/>:<GrFormViewHide onClick={()=>setPassShow(!passShow)}/>}
            </div>
            <div className="form_group password_div">
                <input type={CpassShow===true?"text":"password"} id='confirmPassword' name='confirmPassword' value={confirmPassword} placeholder='Confirm Your Password' onChange={onChange}/>
                {CpassShow===true?<VscEye onClick={()=>setCPassShow(!CpassShow)}/>:<GrFormViewHide onClick={()=>setCPassShow(!CpassShow)}/>}
            </div>
            <div className="form_group">
                <button type='submit' className='secondary_btn'>Create Account</button>
            </div>
    </form>
    <p>Already have an account? <Link className='special_a_tag' to='/login'>Sign In</Link> here</p>
    </div>
</section>



  )
}
 
export default Register