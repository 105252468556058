import DemoSideBar from './DemoSideBar'
import DemoDashboardHeader from './DemoDashboardHeader'
import { Outlet } from 'react-router-dom'
import DemoMobileNav from './DemoMobileNav'




const DashboardPage = () => {

  return (
    <div className='DemoDashboard'>
     <DemoMobileNav/> 
    <DemoSideBar/>
    <section className='Right_container'>
    <DemoDashboardHeader/>
    <Outlet/>

    </section>

 
    
    </div>

  )
}

export default DashboardPage