import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const width =  window.innerWidth
let height
if(width<=900){
   height = 250
}



export function LineChart({data ,title, showTitle}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
          display:false,
        position: 'top',
      },
      title: {
        display: showTitle,
        text: title,
      },
    },
      maintainAspectRatio: false ,
  
  };
  
  return <Line options={options} data={data} height={height}/>;
}
