import {create_checkout_link,reset} from '../../../features/Payment/paymentSlice'
import { useDispatch, useSelector } from 'react-redux'
import "./Pricing.css"
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

console.log(process.env.REACT_APP_ENV)

const plans = [
    {
        name: 'Started',
        description:"For Indiviuals",
        price_id: process.env.REACT_APP_ENV==="production"?'price_1NS2RIKAaxTEgbtScxMV1gAd':'price_1NHwaIKAaxTEgbtSUNMukdrZ',
        Flat_charges: '99',
        free_api_calls:25,
        for_the_rest:" $ 0.89 / Api call"
    },
    {
        name: 'Intermediate',
        description:"For Startups",
        price_id: process.env.REACT_APP_ENV==="production"?'price_1NS2R9KAaxTEgbtSBdsjojZx':'price_1NHwcEKAaxTEgbtSpcTvCHAG',
        Flat_charges: '149',
        free_api_calls:75,
        for_the_rest:" $ 0.69 / Api call"
    },
    {
        name: 'Advanced',
        description:"For Businesses",
        price_id: process.env.REACT_APP_ENV==="production"?'price_1NS2QyKAaxTEgbtSHJ9LrGgR':'price_1NHwdCKAaxTEgbtSKFe8EVER',
        Flat_charges: '199',
        free_api_calls:150,
        for_the_rest:" $ 0.49 / Api call"
    }
]

const Pricing = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {user} =useSelector((store)=>store.auth)
    const {message,isLoading,isSuccess,isError} =useSelector((store)=>store.payment)

    const checkout = (price_id)=>{
        if(user){
            dispatch(create_checkout_link({price_id}))
        // dispatch(create_portal_session())


        }else{
            toast("please Login first")
            navigate('/login')
        }
    }

    useEffect(()=>{
        if(message){
            toast(message)
        }
        dispatch(reset())
    },[message])
  return (
    <section id='pricing'className="pricing">

        <div className="pricing_plan">
        <h3>Pricing Plans</h3>
        <p>Chose a plan Tailored to your needs.</p>
        </div>
        
        <div className="pricing_table">

            { plans.map( (plan, index) => {
        return (
            <div className={index===1?"pricingCard standardCard":"pricingCard"} key={index}>
                <div className="pricingCardheader" >
                <h3>{plan.name}</h3>
                <h5>{plan.description}</h5>
                </div>
                <hr className="pricing_line"/>
                <div className="price_row">
                    <div>
                        <h6>Flat Charges :</h6>
                        <h6 className="amount"><span>$</span><span>{plan.Flat_charges}</span> <span>/month</span></h6>
                    </div>
                    <div className="flex_row">
                        <h6>Free Api Calls :</h6>
                        <h6 className="">{plan.free_api_calls}</h6>
                    </div>
                </div>
                <hr className="pricing_line"/>

                <div className="price_row">
                    <h6>Additional Charges</h6>
                    <h6 className="">{plan.for_the_rest}</h6>
                </div>
                <button className={index===1?"btn":"secondary_btn"} style={{flexShrink:"0", width:"100%"}}onClick={()=>checkout(plan.price_id)}>Subscribe</button>

            </div>
        );
    }) }
        </div>
    </section>
  )
}

export default Pricing