import React from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {toast} from 'react-toastify'
import {getDemoData,getuserData} from '../../features/userData/userDataSlice'
import SideBar from '../../components/SideBar/SideBar'
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader'
import { Outlet, useNavigate } from 'react-router-dom'
import './DashboardPage.css'
import MobileNav from '../../components/MobileNav/MobileNav'





const DashboardPage = () => {
  const {user} = useSelector((store)=>store.auth)
  const {userData}  =useSelector((store)=>store.userData)

  const {CardBackground,CardTextColor}=userData

  const dispatch = useDispatch()
  const navigate = useNavigate()



  useEffect(()=>{
      if(!user){
        toast.info("please Login first")
        navigate('/login')
      }

  },[dispatch,user,navigate])



  return (
    // <div className='DemoDashboard' style={{"--primary_color":CardTextColor,"--bg_color":CardBackground,"card_bg_color":CardBackground,"--third_color":CardTextColor}}>
    <div className='DemoDashboard' style={{"--bg_color":CardBackground,"card_bg_color":CardBackground,"--third_color":CardTextColor}}>
     <MobileNav url='analysis'/> 
    <SideBar/>
    <section className='Right_container'>
    <DashboardHeader/>
    <Outlet/>

    </section>

 
    
    </div>

  )
}

export default DashboardPage