import {TbRefresh} from 'react-icons/tb'
import "./skeltonDashboard.css"
import {BiInfoCircle} from 'react-icons/bi'
import { BarChart } from '../../components/ChartComponents/BarChart/BarChart'
import { LineChart } from '../../components/ChartComponents/LineChart/LineChart'
import { Currency } from "../../pages/Connect/Connect";

const SkeltonDashboard = ({apiData,setApiData}) => {
    const{primaryColor,secondaryColor,tertiaryColor,mainbg,dashboardName, curency}=apiData
    function apiDataUpdate(e) {
      console.log("sss")
      e.preventDefault();
      setApiData({ ...apiData, [e.target.name]: e.target.value });
    }
// /////////////////////////////////////
    const data= {
        LocationAndUsers:{
            labels: [
            "Trondheim","Moss","Vadso","Molde","Sandnes","Skien","Kristiansand","Sandefjord","Bergen"
            ],
            datasets: [
              {
                label: "Total Users",
                data: [
              977,559,459,569,577,557,758,565,564
                ],
                borderColor: tertiaryColor,
                backgroundColor: secondaryColor,
              }
            ]
          },
          AverageRevenueGraph: {
            labels: [
             "2022-08","2022-09","2022-10","2022-11","2022-12","2023-01","2023-02"],
            datasets: [
              {
                label: "Average revenue",
                data: [
                25.397054491899862,25.71265636497425,24.543481053307605,25.209159823120626, 25.100695364238412, 24.76514002333722, 26.8797709923664],
                borderColor: secondaryColor,
                backgroundColor: primaryColor,
              }
            ]
          }
      }
      // //////////////////////////////////////////////////////////////

  return (
    <div className="ColMainContainer">
    <div>
      <p>Background</p>
      <input
      style={{border:"1px solid black"}}
        className="colorpicker"
        type="color"
        id="DashboardColor0"
        name="mainbg"
        placeholder="Enter Dashboard Color"
        value={apiData.mainbg}
        onChange={(e) => apiDataUpdate(e)}
      />
      <p>primaryColor</p>
      <input
        className="colorpicker"
        type="color"
        id="DashboardColor1"
        name="primaryColor"
        placeholder="Enter Dashboard Color"
        value={apiData.primaryColor}
        onChange={(e) => apiDataUpdate(e)}
      />
      <p>secondaryColor</p>
      <input
        className="colorpicker"
        type="color"
        id="DashboardColor2"
        name="secondaryColor"
        placeholder="Enter Dashboard Color"
        value={apiData.secondaryColor}
        onChange={(e) => apiDataUpdate(e)}
      />
      <p>Dashboard name</p>
      <input
        type="text"
        name="dashboardName"
        placeholder="Dashboard name"
        onChange={(e) => apiDataUpdate(e)}
      />
      <p>Curency</p>
      <Currency
        data={apiData}
        setData={setApiData}
        inputValueupdate={apiDataUpdate}
      />
    </div>
    <div className='skdashboard_container' style={{color:primaryColor , background:mainbg}}>
    <div className="date_control">
      <h5 className='dash_heading'>{dashboardName}</h5>
      <div className='control_buttons' style={{borderColor:secondaryColor}}>
        <div className='skRefreshData 'style={{backgroundColor:secondaryColor,color:primaryColor}} >
          Refresh Data
        <TbRefresh/>
        </div>
          <button className='skdeactive_filter'>Yearly</button>
          <button className='skactive_filter' style={{color:secondaryColor}}>Last 12 Months</button>
          <button className='skdeactive_filter'>Last 30 Days</button>
      </div>  
    </div>
    <div className="skdashboard_box card_Box" >
       <div className='card'style={{background:secondaryColor}} >
        <h4>Total Revenue</h4>
        <h3>50 {curency}</h3>
      </div>
      <div className='card' style={{background:secondaryColor}}>
        <h4>Average Revenue</h4>
        <h3>10 {curency}</h3>
      </div>
      <div className='card' style={{background:secondaryColor}}>
        <h4>Total Rides</h4>
        <h3>5</h3>
      </div>
      <div className='card' style={{background:secondaryColor}}>
        <h4>Users</h4>
        <h3>10</h3>
      </div>
      
      </div>  
      {/* //Graph1 */}
      
          <div className='row2'>
          <div className="left_box">
        <div className="info"><BiInfoCircle/></div>

        <BarChart data={data.LocationAndUsers}  title='Total Users over Different Locations' showTitle={true}/>
        </div>
        <div className="right_box">
        <div className="info"><BiInfoCircle/></div>

        <LineChart data={data.AverageRevenueGraph}  title='Average Revenue Analysis' showTitle={true}/>
        </div>

      </div>
      </div>
  </div>

  )
}

export default SkeltonDashboard