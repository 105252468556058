import React from 'react'
import { BarChart } from '../../components/ChartComponents/BarChart/BarChart'
import { LineChart } from '../../components/ChartComponents/LineChart/LineChart'
import { useState } from 'react'
import {TbRefresh} from 'react-icons/tb'
import {toast} from 'react-toastify'
import ImpactCard from '../../components/ImpactCard/ImpactCard'
import {DummyData} from '../../assets/DummyData'
import InfoBox from '../../components/InfoBox/InfoBox'



const DemoImpactAnalysis = () => {
  const [activeFilter, setActiveFilter ] = useState('days30')
  const [filter,setFilter] = useState('days30')
  const [show, setShow] = useState({ graph1:false, graph2:false})

  const refreshData =()=>{
   toast.info("Please Connect Your Dashboard")
  }


  const ChoseFilter = (filter) =>{
    setActiveFilter(filter)
    setFilter(filter)
  }



  const {year,month12,days30}=DummyData
  let data = filter==='days30'?days30:filter==='month12'?month12:year

  return (
    <div className='dashboard_container'>
    <div className="date_control">
      <h5 className='dash_heading'>Impact Analysis</h5>



      <div className='control_buttons'>
        <div className='RefreshData btn' onClick={refreshData}>
          Refresh Data
        <TbRefresh/>
        </div>
          <button  onClick={()=>ChoseFilter('year')} className={activeFilter==='year' ? 'activeFilter':'deactive_filter'}>yearly</button>
          <button  onClick={()=>ChoseFilter('month12')} className={activeFilter==='month12' ? 'activeFilter':'deactive_filter'}>Last 12 Months</button>
          <button  onClick={()=>ChoseFilter('days30')} className={activeFilter==='days30' ? 'activeFilter':'deactive_filter'}>Last 30 Days</button>
      </div>  
    </div>
    
    <div className="dashboard_box card_Box">
       <ImpactCard heading='Carbon Dioxide Saved' amount={data.cardData.totaCo2Saved} backgroundColor='#C2EED4' color='#33784F' svg='green' unit="kg"/>
       <ImpactCard heading='Fuel Saved' amount={data.cardData.totalFuelSaved} backgroundColor='#F6EFFE' color='#722ED1'  svg='purple'  unit="liter"/>
       <ImpactCard heading='Trees Relaxed' amount={data.cardData.totalTreesRelaxed} backgroundColor='#C2EED4' color='#33784F' svg='green'/>
      </div>  






      <div className="row2">
        <div className="left_box">
        <InfoBox show={show} setShow={setShow} graph="graph1" info={`This represents the number of trees relaxed as well as liters of fuel saved ${filter==="days30"?"in last 30 days of your data.":filter==="month12"?"in last 12 months of your data.":"over years"} `} />
        <BarChart data={data.FuelAndTreesGraph} title='Fuel Saved & Trees Relaxed' showTitle={true}/>
        </div>
        <div className="right_box">
        <InfoBox show={show} setShow={setShow} graph="graph2" info={`This represents the kilograms of carbon emissions saved ${filter==="days30"?"in last 30 days of your data.":filter==="month12"?"in last 12 months of your data.":"over years"} `} />
        <LineChart data={data.Co2Saved} title='CO2 Saved' showTitle={true}/>

        </div>
      </div>


      </div>
  )
}

export default DemoImpactAnalysis


