import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/Auth/AuthSlice'
import userDataReducers from '../features/userData/userDataSlice';
import connectFormReducers from '../features/connectForm/ConnectFormSlice';
import NewsLetterReducer from '../features/NewsLetter/NewsLetter';
import ProfilDataReducer from '../features/ProfileData/ProfileData'
import RecordSliceReducer from '../features/RecordSlice/RecordSlice';
import ForgotPasswordReducer from '../features/ForgotPassword/ForgotPasswordSlice';
import ResetPasswordReducer from '../features/ResetPassword/ResetPasswordSlice';
import multipleDasboardReducer from '../features/multipleDashboard/multipleDasboard';
import apiConnectReducer from '../features/ApiConnect/apiConnect';
import paymentReducer from '../features/Payment/paymentSlice';
export const store = configureStore({
  reducer: {
    auth:authReducer,
    userData:userDataReducers,
    connectForm:connectFormReducers ,
    NewsLetter:NewsLetterReducer,
    ProfileData:ProfilDataReducer,
    RecordedData:RecordSliceReducer,
    ForgotPassword:ForgotPasswordReducer,
    ResetPassword:ResetPasswordReducer,
    MultipleDasboard:multipleDasboardReducer,
    ApiConnect:apiConnectReducer,
    payment:paymentReducer
  },
});

//test comment