import React, { useEffect } from 'react'
import {create_portal_session,reset} from '../../features/Payment/paymentSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {toast} from 'react-toastify'

import GoBackButton from "../../components/GoBackButton/GoBackButton";
const PricingPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {user} =useSelector((store)=>store.auth)
    const {message,isLoading,isSuccess,isError} =useSelector((store)=>store.payment)


    const OpenPortal = ()=>{
        if(user){
        dispatch(create_portal_session())

        }else{
            toast("please Login first")
            navigate('/login')
        }
    }
    useEffect(()=>{
        if(message){
            toast(message)
        }
        dispatch(reset())
    },[message])
  return (
    <section className='PricingPage'>
    <div className="ConnectHeading">
    <GoBackButton/>

    <h3>Manage Your Subscriptions</h3>
    </div>

    <div>
        {/* <Pricing/> */}
        <div className="no_data">
        <h3>Manage Your subscriptions easily</h3>
        <p>Renew or cancel any time with one click</p>
        <button onClick={() => OpenPortal()} className="btn">Open Portal</button>

        </div>
    </div>
    </section>
  )
}

export default PricingPage