import React, { useEffect, useState } from "react";
import "./APIconnectivity.css";
import { useLocation, useNavigate} from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner"
import SkeltonDashboard from "../../components/skeltonDashboard/skeltonDashboard";
import { AiOutlineCopy } from "react-icons/ai";
import { createApiData, reset } from "../../features/ApiConnect/apiConnect";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import GoBackButton from "../../components/GoBackButton/GoBackButton";
import {generateSecretKey,copyToClipBoard} from '../../utils/LocalStorageFunctions'
import {preData} from '../../assets/SVG'
const APIconnectivity = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const [serviceType, setServiceType] = useState("17");
  const [copySuccess, setCopySuccess] = useState({ secret: "", link: "",data:"" });
  const [apiData, setApiData] = useState({ primaryColor: "#5000BE", secondaryColor: "#D184FF", mainbg: "#FFFFFF", dashboardName: "My Dashboard",curency: "$", secretKey:null });
  const { isErrorAC, isSuccessAC, isLoadingAC, messageAC,savedAC,secretKey } = useSelector((store) => store.ApiConnect);
  const { user } = useSelector((store) => store.auth);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
    
  const ConnectionType = searchParams.get('ConnectionType');
  const submitApiData = (e) => {
    e.preventDefault();
    if (!apiData.secretKey || !serviceType) {
      toast.error("something went wrong");
    } else {
      // valid data which can be posted to backened
      dispatch(createApiData({ secretKey:apiData.secretKey, serviceType })
      );
    }
  };


  useEffect(() => {
    if(!user){
      toast.info("please Login first")
      navigate('/login')
    }
    if (isErrorAC) {
      console.log("error")
      toast.error(messageAC);
    }
    if (isSuccessAC) {
      console.log("sucess")
      toast.info(messageAC);
    }
    // return () => dispatch(reset());
  }, [isErrorAC, messageAC, isSuccessAC, dispatch]);

  useEffect(() => {
    generateSecretKey(apiData,setApiData)
  }, []);
  if(isLoadingAC){
    return(
      <Spinner />
    )
  }
  return (
    <section className="APIconnectivity">
      <div className="ConnectHeading">
        <GoBackButton/>
        <h3>Connect via API</h3>
        <p>We provide Api connectivity for your analytical dashboard</p>
      </div>

      {savedAC===true ? (
      <div className="api_container">
        <div className="api_container_child">
        <h4>Please Copy Your Secret key</h4>
        <p>
          Please copy this secret key and make sure no one has access to it.
        </p>
        <div>
          <div className="copy_container">
          <h6>{secretKey}</h6>
          {secretKey !==""? 
            <AiOutlineCopy id="secret" onClick={(e) => copyToClipBoard(e, secretKey,copySuccess,setCopySuccess)} />:
            <AiOutlineCopy id="secret" onClick={(e) => copyToClipBoard(e, apiData.secretKey,copySuccess,setCopySuccess)} />
          }
          <span>{copySuccess.secret}</span>
          </div>
        </div>
        </div>
     

        <div className="api_container_child">
        <h4>Request this Api with requested data</h4>
            <p>
              Please copy this Api and make sure no one has access to it.
            </p>
            <div>
              <div className="copy_container">
              <h6>{`${process.env.REACT_APP_SERVER_URL}/api/${ConnectionType}/${user._id}`}</h6>
              <AiOutlineCopy   id="link" onClick={(e) => copyToClipBoard( e,`${process.env.REACT_APP_SERVER_URL}/api/${ConnectionType}/${user._id}`,copySuccess,setCopySuccess )  }/>
                <span>{copySuccess.link}</span>
              </div>
            </div>
       
        </div>
        
      </div>
      ) : 
      <div className="no_data">
        <h3>Create your Api with one click</h3>
        <p>Provided Api is confidential, Please avoid sharing this information</p>
        <button onClick={(e) => submitApiData(e)} className="btn">Access Api</button>

        </div>
        }

      {savedAC===true && <div>
        
      {ConnectionType==="DashboardReport" ? <SkeltonDashboard apiData={apiData} setApiData={setApiData}/>:
      ConnectionType==="impactReport" ?
      <div className="inputName">
          <label htmlFor="">Enter your Dashboard Name</label>
          <input type="text" onChange={(e)=>setApiData({...apiData,['dashboardName']:e.target.value})}/>
      </div>:null
        
      }
        <div className="pre_container">
          <h4>Configuration Object for your dashboard</h4>
          <p>Send This Object along with data in the request, you can modify your dashboard using these keys.</p>
          {ConnectionType==="DashboardReport" ?

          <pre className="connectionTypePre">
              {JSON.stringify({
                    colors:
                      {
                        primaryColor:apiData.primaryColor,
                        secondaryColor:apiData.secondaryColor,
                        mainbg:apiData.mainbg
                      },
                    dashboardName:apiData.dashboardName,
                    curency:apiData.curency,
                    secretKey:secretKey,
                    data:preData.completeDashboard
                  },null,14)}
            </pre> :

            ConnectionType==="impactReport" ?
             <pre className="connectionTypePre">
             {JSON.stringify({
                   dashboardName:apiData.dashboardName,
                   secretKey:secretKey,
                   data:preData.ImpactDashbaord
                 },null,14)}
           </pre>:

          ConnectionType==="DashboardData" ?
           <pre className="connectionTypePre">
           {JSON.stringify({
                 secretKey:secretKey,
                 data:preData.completeDashboardData
               },null,14)}
         </pre>:

          ConnectionType==="ImpactData" ?
          <pre className="connectionTypePre">
          {JSON.stringify({
                secretKey:secretKey,
                data:preData.ImpactDashbaordData
              },null,14)}
          </pre>
          :null
             } 
        </div>
                  
      </div>}
    </section>
  );
};

export default APIconnectivity;
